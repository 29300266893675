<template>
    <div>
        <LiefengContent v-if="!(this.$route.query.id && this.$route.query.menuId && this.$route.query.status)">
            <template v-slot:title>{{ menuCodeObj.columnName || "定向采集类" }}</template>
            <template v-slot:toolsbarLeft>
                <Button type="primary" icon="ios-cube-outline" @click="$router.push({ path: '/formindex', query: { path: $route.path, menuId: $route.query.menuId } })" style="margin-right: 10px">
                    表单模板库
                </Button>
                <Button type="primary" icon="ios-cube-outline" @click="openNumCount" style="margin-right: 10px" v-if="buttonRoot == '1003'">行为数据分析</Button>
                <Button icon="ios-add" type="primary" v-if="buttonRoot == '1001'" @click="addQues">新增</Button>
            </template>
            <template v-slot:toolsbarRight>
                <Form :model="searchData" :label-colon="true" :inline="true" class="search">
                    <FormItem label="标题" :label-width="50" style="margin-bottom: 0">
                        <Input v-model="searchData.title" placeholder="请输入标题" style="width: 160px"></Input>
                    </FormItem>
                    <FormItem label="发布范围" :label-width="75">
                        <Cascader transfer v-model="searchData.dataScopeList" change-on-select :load-data="loadCommunity" :data="releaseScopeList"></Cascader>
                    </FormItem>
                    <FormItem label="拟制人" :label-width="60">
                        <Input v-model="searchData.orgName" style="width: 160px"></Input>
                    </FormItem>
                    <Button type="primary" icon="ios-search" @click="search" style="margin-right: 10px">查询</Button>
                    <Button type="success" @click="reset" icon="ios-refresh" style="margin-right: 10px">重置</Button>
                </Form>
            </template>
            <template v-slot:contentArea>
                <div class="table-left">
                    <Menu theme="light" :active-name="activeMenu" @on-select="selectMenu">
                        <MenuGroup title="信息状态">
                            <MenuItem name="2" v-if="buttonRoot == '1001'">待提交/已驳回({{ status2 }})</MenuItem>
                            <MenuItem name="1" v-if="buttonRoot == '1002'">待审核({{ status1 }})</MenuItem>
                            <MenuItem name="3" v-if="buttonRoot == '1003'">待发布({{ status3 }})</MenuItem>
                            <MenuItem name="4">
                                已发布
                                <Tooltip placement="right" content="已发布的内容居民在小程序、APP等终端可正常查看到。"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                            <MenuItem name="5">
                                已过期
                                <Tooltip placement="right" content="到了截止时间内容则为已过期，在小程序的全部和往期回顾中可查看到。"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                            <MenuItem name="6">
                                已归档
                                <Tooltip placement="right" content="归档后的内容在小程序、APP等终端则不显示，对用户不可见。"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                            <MenuItem name="7">全部信息</MenuItem>
                        </MenuGroup>
                    </Menu>
                </div>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="tableSelect"
                ></LiefengTable>
            </template>
        </LiefengContent>
        <!-- 新增修改 -->
        <LiefengModal :title="addChangeTitle" :value="addChangeStatus" @input="addChangeStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <AddChange
                    :buttonRoot="buttonRoot"
                    v-if="addChangeStatus"
                    :activeId="activeId"
                    :addChangeIden="addChangeIden"
                    @saveSuccess="saveSuccess"
                    :menuCodeObj="menuCodeObj"
                    ref="addChange"
                    :formData="formData"
                    :addType="addType"
                    :quoteNum="quoteNum"
                ></AddChange>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        cancelModalTip = true
                        addChangeStatusFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" @click="save">保存</Button>
                <!--        <Button type="warning" style="margin-left: 10px" @click="contentFn">预览文本</Button>-->
            </template>
        </LiefengModal>
        <!-- 审核/发布 -->
        <LiefengModal :title="publishTitle" :value="publishStatus" @input="publishStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <Publish
                    :buttonRoot="buttonRoot"
                    v-if="publishStatus"
                    :activeId="activeId"
                    :infoStatus="infoStatus"
                    @saveSuccess="saveSuccess"
                    :menuCodeObj="menuCodeObj"
                    :publicRecommend="publicRecommend"
                    ref="publish"
                    :orgCode="orgCode"
                ></Publish>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="publishStatusFn(false)" style="margin-right: 10px">取消</Button>
                <Button type="warning" @click="reject" style="margin-right: 10px" v-if="infoStatus == '待审核' || infoStatus == '待发布'">驳回</Button>
                <Button type="success" @click="publish">
                    {{ publishButtonName }}
                </Button>
                <Button type="warning" style="margin-left: 10px" v-if="infoStatus == '待审核' && buttonRoot == '1002'" @click="passAndSend">通过并加急发布</Button>
            </template>
        </LiefengModal>
        <!-- 查看 -->
        <LiefengModal title="查看问卷" :value="seeStatus" @input="seeStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <Publish :buttonRoot="buttonRoot" ref="publish1" v-if="seeStatus" :activeId="activeId" :menuCodeObj="menuCodeObj"></Publish>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" :style="{ marginRight: buttonRoot == '1001' ? '10px' : '' }" @click="seeStatusFn(false)">关闭</Button>
                <Button type="primary" :style="{ display: buttonRoot == '1001' ? '' : 'none' }" @click="seeToChange">修改</Button>
                <!--        <Button type="warning" style="margin-left: 10px" @click="contentFn1">预览文本</Button>-->
            </template>
        </LiefengModal>
        <!-- 公众号链接 -->
        <LiefengModal title="分享并预览" :value="officialStatus" @input="officialStatusFn" width="1150px" height="calc(100vh - 100px)" class="signInOut">
            <template v-slot:contentarea>
                <div>
                    <Select v-model="officialCity" transfer style="width: 140px" placeholder="请选择市" filterable @on-change="val => getOfficialScopeList(signInObj.id, '3', val)">
                        <Option :value="item.orgCode" v-for="(item, index) in officialCityList" :key="index">{{ item.orgName }}</Option>
                    </Select>
                    <Select
                        v-model="officialRegion"
                        transfer
                        style="width: 140px"
                        placeholder="请选择区"
                        filterable
                        @on-clear="clearRegion"
                        clearable
                        @on-change="val => getOfficialScopeList(signInObj.id, '4', val)"
                    >
                        <Option :value="item.orgCode" v-for="(item, index) in officialRegionList" :key="index">{{ item.orgName }}</Option>
                    </Select>
                    <Select
                        clearable
                        v-model="officialStree"
                        transfer
                        style="width: 140px"
                        placeholder="请选择街道/乡镇"
                        filterable
                        @on-clear="clearStree"
                        @on-change="val => getOfficialScopeList(signInObj.id, '5', val)"
                    >
                        <Option :value="item.orgCode" v-for="(item, index) in officialStreeList" :key="index">{{ item.orgName }}</Option>
                    </Select>
                    <Select v-model="officialProject" transfer :label-in-value="true" style="width: 270px" :max-tag-count="2" placeholder="请选择社区" multiple filterable @on-change="changeProject">
                        <Option :value="item.orgCode" v-for="(item, index) in officialProjectList" :key="index">{{ item.orgName }}</Option>
                    </Select>
                    <Button type="primary" style="margin: 0 10px" @click="makeOfficialQRCode">生成链接</Button>
                    <!-- <Button type="info" :disabled="makeOffDisabled" style="margin:0 10px" @click="makeOfficialQRcodeList">按社区生成分享码</Button> -->
                    <Poptip placement="bottom-end" word-wrap width="500">
                        <Icon type="md-help" />
                        <div slot="content" style="text-indent: 2em">
                            <p>说明:</p>
                            <p>生成分享码: 是将选择的当前范围生成一个二维码进行推广分享(一张)</p>
                            <p>按社区生成分享码:是将选择的当前范围下包含的所有社区按照社区为</p>
                            <p>单位生成各自的社区二维码进行推广分享(多张)</p>
                            <p>二维码的样式与下载时选择的模板一致</p>
                        </div>
                    </Poptip>
                </div>
                <div v-if="codeImgUrl && officialUrl && showMake != true && linkMake == true">
                    <h3 style="margin-top: 10px">该链接可以用于配置到公众号进行推广:</h3>
                    <div style="display: flex; justify-content: space-between; line-height: 32px; margin: 14px auto">
                        <span>公众号链接：</span>
                        <Input v-model="officialUrl" search enter-button="复制" style="flex: 1; margin-right: 100px" @on-search="copyShareCode"></Input>
                    </div>
                    <!-- <div
            style="
              display: flex;
              justify-content: space-between;
              line-height: 32px;
              margin: 14px auto;
            "
          >
            <span style="width:84px">短信链接：</span>
            <Input
              v-model="noteUrl"
              search
              enter-button="复制"
              style="flex: 1;margin-right: 100px"
              @on-search="copyNoteUrl"
            ></Input>
          </div> -->
                    <h3>推广二维码已生成，可下载后直接分享二维码到微信（朋友、朋友圈）</h3>
                    <div class="qrdiv">
                        <div style="display: flex; align-items: center; justify-content: center;margin-right: 200px">
                            <div :class="!textActive ? 'qrcode qrcode-antoer' : 'qrcode'" ref="haibaoImg" :style="{ background: `url('${qrimg}') no-repeat` }">
                                <div class="h1h3">
                                    <!-- <div class="h1h3 fontsize3">
                  <div> -->
                                    <div :class="qrOCdeName.length <= 8 ? !textActive ? 'fontsize1 fontactive':'fontsize1' : qrOCdeName.length > 8 && qrOCdeName.length <= 30 ? !textActive ? 'fontsize2 fontactive':'fontsize2' : !textActive ? 'fontsize3 fontactive':'fontsize3'">
                                        {{ qrOCdeName }}
                                    </div>

                                    <!-- <h3>入驻{{qrOCdeName}}</h3> -->
                                </div>
                                <!-- qrOCdeName -->
                                <span style="letter-spacing: 3px; z-index: 99">{{ qrCodeOrgName }}</span>
                                <img :src="codeImgUrl + '?' + new Date().getTime()" alt ref="qrCode" crossorigin="anonymous" style="z-index: 98" />
                            </div>
                             <div style="width: 130px; height: 100%; margin-left: 30px;margin-bottom: 480px">
                                <Button type="primary" style="margin-bottom: 20px; width: 130px" @click="downloadImgCode">下载纯二维码</Button>
                                <Button type="warning" @click="mergeImage">下载宣传二维码</Button>
                            </div>
                            <div :class="!textActive ? 'diyimg' :'diyimg diyimg-right'">
                                <Progress v-if="file.showProgress" :percent="file.percentage" :stroke-width="5"></Progress>
                                <Upload
                                    ref="imgUpload"
                                    :before-upload="beforeUpload"
                                    :format="['jpg', 'jpeg', 'png', 'gif']"
                                    style="display: inline-block"
                                    :on-format-error="imageError"
                                    :on-progress="imageProgress"
                                    :on-success="(value, file) => imageSuccess(file)"
                                    :action="action"
                                    :data="uploadData"
                                    :on-remove="imageRemove"
                                    :show-upload-list="false"
                                >
                                    <Button icon="ios-cloud-upload-outline" type="info">自定义更换背景</Button>
                                </Upload>
                                <Button v-if="qrimg" type="success" @click="cropperFn" style="margin-left: 10px">裁剪图片</Button>
                                <div class="checkimg">
                                    <div class="imglist">
                                        <img @click="changeQrimgNo(item, index)" :class="qrimgNo == index + 1 ? 'active' : ''" v-for="(item, index) in qrimgList" :key="item" :src="item" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <img :src="codeImgUrl" alt width="200" style="display: block;margin-bottom: 10px" />
            <Button type="primary" @click="downloadImgCode">下载二维码</Button> -->
                    </div>
                </div>
                <div
                    v-if="!codeImgUrl && !officialUrl && linkMake == false && showMake == false"
                    style="
                        height: calc(100vh - 305px);
                        overflow: auto;
                        margin: 20px;
                        border-radius: 4px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #ccc;
                        color: #fff;
                        font-size: 20px;
                    "
                >
                    可生成街道或者社区的推广链接和二维码
                </div>

                <div v-if="showMake == true && linkMake != true">
                    <h3>推广二维码已生成，可下载后直接分享二维码到微信（朋友、朋友圈）</h3>
                    <div class="qrdiv">
                        <div style="display: flex; align-items: center; justify-content: center;margin-right: 200px">
                            <div class="qrcode" ref="refAllImage">
                                <div class="h1h3">
                                    <h1>{{ qrOCdeName | spliceTitle }}</h1>
                                </div>
                                <p>微信扫码查看详情服务</p>
                                <img class="qrdiv-image-div" crossorigin="anonymous" src="https://property-liefeng.oss-cn-shenzhen.aliyuncs.com/TEST/zjsm/20220113/20220113165945pOnBHwAz.png" />
                            </div>
                            <div style="width: 130px; height: 100%; margin-left: 30px">
                                <Button type="warning" :disabled="isDwonLoad" @click="downAllImage">下载宣传二维码</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </LiefengModal>
        <!-- 相关链接 -->
        <LiefengModal title="相关链接" :value="aboutLinkStatus" @input="aboutLinkStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <AboutLink ref="aboutLink" @saveSuccess="saveSuccess" v-if="aboutLinkStatus" :menuCodeObj="menuCodeObj" :infoId="infoId" :infoCode="infoCode"></AboutLink>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        cancelModalTip = true
                        aboutLinkStatusFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" @click="aboutLinkSave">保存</Button>
            </template>
        </LiefengModal>
        <!-- zhw新增 -->
        <!--更换栏目-->
        <LiefengModal title="更换栏目" :value="ChangeColumn" @input="ChangeColumnFn" :fullscreen="false">
            <template v-slot:contentarea>
                <div style="height: 200px">
                    <Cascader style="width: 50%; padding-top: 80px; margin-left: 120px" :data="cascaderData" v-model="cascadValue"></Cascader>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        cancelModelTip = true
                        ChangeColumnFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" @click="changeSave">保存</Button>
            </template>
        </LiefengModal>
        <!--用户操作记录-->
        <LiefengModal title="用户操作记录" :value="userChooseTle" @input="UserChooseFn" :fullscreen="true">
            <template v-slot:contentarea>
                <CheckRecord :businessType="sendMessage.businessType" :infoId="sendMessage.infoId" :isShow="isOpenUserMessage" @userSave="userSave"></CheckRecord>
            </template>
        </LiefengModal>
        <!--行为数据分析-->
        <LiefengModal title="行为数据分析" :value="numFenxiStatus" @input="numFenxiFn" :fullscreen="true">
            <template v-slot:contentarea>
                <Analysis :isTrue="isTrue" :infoIdLis="infoIdLis"></Analysis>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" @click="numFenxiFn(false)">关闭</Button>
            </template>
        </LiefengModal>
        <!-- 导出弹窗 -->
        <LiefengModal title="导出" :value="exportExcelStatus" @input="exportExcelStatusFn" :fullscreen="false">
            <template v-slot:contentarea>
                <div style="height: 300px">
                    <Form :label-colon="true" :label-width="100" style="padding-right: 20px">
                        <FormItem label="所属社区">
                            <Select v-model="marital" placeholder="请选择社区" filterable @on-change="selectCommunity">
                                <Option v-for="(item, index) in communityList" :key="index" :value="item.code">{{ item.fullName }}</Option>
                            </Select>
                        </FormItem>
                    </Form>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    style="margin-right: 10px"
                    @click="
                        exportExcelTip = true
                        exportExcelStatusFn(false)
                    "
                >
                    取消
                </Button>
                <Button type="primary" @click="canfirmExport">确认导出</Button>
            </template>
        </LiefengModal>

        <!--对比分析-->
        <LiefengModal title="对比分析" :value="contrastStatus" @input="contrastFn" :fullscreen="true">
            <template v-slot:contentarea>
                <contrast :infoId="contrastInfoId" :functionType="conFunctionType" :businessType="conBusinessType" :isShow="contrastShow"></contrast>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" @click="contrastClose">返回</Button>
            </template>
        </LiefengModal>

        <!-- 模板 -->
        <LiefengModal :title="`${menuCodeObj.columnName}新增` || '问卷采集类新增'" :value="templateStatus" @input="changeTemplateStatus" width="1200px" height="625px">
            <template v-slot:contentarea>
                <Templatetable
                    @getActiveId="activeId"
                    @changeAddType="changeAddType"
                    ref="Templatetable"
                    :columnCode="columnCode"
                    @addChangeStatusFn="addChangeStatusFn"
                    @setFormData="setFormData"
                    @quoteData="quoteData"
                ></Templatetable>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-right: 10px" type="primary" @click="changeTemplateStatus(false)">关闭</Button>
            </template>
        </LiefengModal>
        <!-- 关联数据 -->
        <LiefengModal title="关联数据" :value="relationStatus" @input="changeRelationStatus" :fullscreen="true">
            <template v-slot:contentarea>
                <Relation ref="relation" :quesDate="quesDate" :showNum="showNum"></Relation>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-right: 10px" type="primary" @click="changeRelationStatus(false)">关闭</Button>
            </template>
        </LiefengModal>
        <!-- 分享二维码背景图片裁剪弹窗 -->
        <LiefengModal @input="cropperCancel" width="600px" height="500px" :value="cropperStatus">
            <template v-slot:contentarea>
                <!-- <Mymodel
            :fullscreen="false"
            :value="toggle1"
            :toggle="true"
            :textObj="textObj"
            title="文本预览"
            :toggle-fn2="toggleFn2"
        >
        </Mymodel> -->
                <div class="cropper-content" v-if="cropperStatus">
                    <div class="cropper" style="text-align: center">
                        <vueCropper
                            ref="cropper"
                            :img="option.img"
                            :outputSize="option.size"
                            :outputType="option.outputType"
                            :info="true"
                            :full="option.full"
                            :canMove="option.canMove"
                            :canMoveBox="option.canMoveBox"
                            :original="option.original"
                            :autoCrop="option.autoCrop"
                            :fixed="option.fixed"
                            :fixedNumber="option.fixedNumber"
                            :centerBox="option.centerBox"
                            :infoTrue="option.infoTrue"
                            :fixedBox="option.fixedBox"
                        ></vueCropper>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="cropperCancel(false)" style="margin-right: 10px">取消</Button>
                <Button type="primary" @click="cropperFinish">裁剪</Button>
            </template>
        </LiefengModal>

        <!-- 推送 -->
        <LiefengModal title="推送" :value="pushStatus" @input="changePushStatus" width="400px">
            <template v-slot:contentarea>
                <Form>
                    <FormItem>
                        <span slot="label" class="validate">机构名称</span>
                        <Input style="width: 300px" v-model="pushObj.orgName" :maxlength="20" disabled placeholder="机构名称"></Input>
                    </FormItem>
                    <FormItem>
                        <span slot="label" class="validate">通知内容</span>
                        <Input style="width: 300px" v-model="pushObj.title" :maxlength="20" placeholder="请输入通知内容"></Input>
                    </FormItem>
                </Form>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-right: 10px" type="primary" @click="customPush">推送</Button>
                <Button style="margin-right: 10px" type="primary" @click="changePushStatus(false)">关闭</Button>
            </template>
        </LiefengModal>

        <LiefengModal title="更换栏目" :value="synchroniZationStatus" @input="synchroniZationStatusFn" :fullscreen="false">
            <template v-slot:contentarea>
                <Synchron v-if="synchroniZationStatus" :infoId="synchronId" :functionType="menuCodeObj.functionType" ref="Synchron"></Synchron>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        synchroniZationStatusTip = true
                        synchroniZationStatusFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" @click="saveSynchroniZation">保存</Button>
            </template>
        </LiefengModal>
        <LiefengModal title="指定人员" :value="personStatus" @input="personStatusStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <Person ref="person" v-if="personStatus" :selectList="selectPersonList" :infoId="personInfoId"></Person>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="personStatusStatusFn(false)" style="margin-right: 10px">
                    取消
                </Button>
                <Button type="primary" @click="savePerson">保存</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
//@route('/questionnaireindex')
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
import AddChange from "@/views/directional/childrens/addChange"
import Publish from "@/views/directional/childrens/publish"
import AboutLink from "@/views/directional/childrens/aboutLink"
import CheckRecord from "@/views/directional/childrens/checkRecord"
import Analysis from "@/views/directional/childrens/analysis"
import contrast from "@/views/directional/childrens/contrast"
import Templatetable from "@/views/directional/childrens/templatetable"
import Relation from "@/views/directional/childrens/relationdata"
import html2canvas from "html2canvas"
import { qrcodeMixin } from "./mixin/qrcodeMixin.js"
import Synchron from "@/views/activity/childrens/synchron"
import Person from "@/views/directional/childrens/person"

export default {
    mixins: [qrcodeMixin],
    data() {
        return {
            searchData: { dataScopeList: [] },
            // 发布范围
            releaseScopeList: [],
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "标题",
                    key: "title",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "#2d8cf0",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    textAlign: "center",
                                },
                                on: {
                                    click: () => {
                                        this.activeId = params.row.id
                                        this.seeStatus = true
                                    },
                                },
                            },
                            params.row.title
                        )
                    },
                },

                {
                    title: "发布范围",
                    key: "releaseScope",
                    width: 300,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    display: "flex",
                                    justifyContent: "center",
                                },
                            },
                            [
                                h(
                                    "div",
                                    {
                                        style: {
                                            paddingTop: "5px",
                                            paddingRight: "10px",
                                        },
                                    },
                                    params.row.releaseScope
                                ),
                                h(
                                    "Button",
                                    {
                                        on: {
                                            click: () => {
                                                this.$Message.loading({
                                                    content: "正在加载信息，请稍等",
                                                })
                                                this.selectPersonList = []
                                                this.personInfoId = params.row.id
                                                this.$get("/info/api/pc/information/member/selectByInformationId", {
                                                    informationId: params.row.id,
                                                }).then(res => {
                                                    this.$Message.destroy()
                                                    if (res.code == 200) {
                                                        this.selectPersonList = res.dataList.map(item => {
                                                            return {
                                                                ...item,
                                                                name: item.userName,
                                                            }
                                                        })
                                                    }
                                                })
                                                this.personStatusStatusFn(true)
                                            },
                                        },
                                    },
                                    "指定人员"
                                ),
                            ]
                        )
                    },
                },
                {
                    title: "公布时效",
                    key: "startTime",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.startTime + " 到 " + params.row.endTime)
                    },
                },
                {
                    title: "实名认证",
                    key: "authentication",
                    width: 100,
                    align: "center",
                },
                {
                    title: "拟制人",
                    key: "createName",
                    width: 100,
                    align: "center",
                },
                {
                    title: "推荐",
                    key: "recommend",
                    width: 165,
                    align: "center",
                    render: (h, params) => {
                        return (
                            "div",
                            [
                                h(
                                    "RadioGroup",
                                    {
                                        attrs: {
                                            value: params.row.recommend,
                                        },
                                        on: {
                                            "on-change": status => {
                                                this.infoId = params.row.id
                                                this.recommend = status
                                                this.sortNum = params.row.sort
                                                this.orgCode = params.row.orgCode
                                                this.sortChangeFn({
                                                    pushChannel: params.row.pushChannel,
                                                    status:
                                                    params.row.status.indexOf("待审核") > -1  ?  '1' :
                                                    params.row.status.indexOf("审核不通过") > -1  ?  '2' :
                                                    params.row.status.indexOf("待发布") > -1  ?  '3' :
                                                    params.row.status.indexOf("已发布") > -1  ?  '4' :
                                                    params.row.status.indexOf("已过期") > -1  ?  '5' :
                                                    params.row.status.indexOf("已归档") > -1  ?  '6' :
                                                    ''
                                                })
                                            },
                                        },
                                    },
                                    [
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "1",
                                                    disabled: params.row.status == "已发布" || params.row.status == "已过期" ? false : true,
                                                },
                                            },
                                            "推荐"
                                        ),
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "0",
                                                    disabled: params.row.status == "已发布" || params.row.status == "已过期" ? false : true,
                                                },
                                            },
                                            "不推荐"
                                        ),
                                    ]
                                ),
                            ]
                        )
                    },
                },
                {
                    title: "排序",
                    key: "sort",
                    width: 110,
                    align: "center",
                    render: (h, params) => {
                        return h("InputNumber", {
                            attrs: {
                                min: 1,
                                max: 999999999,
                                value: params.row.sort,
                                precision: 0,
                            },
                            style: {
                                width: "100%",
                            },
                            on: {
                                "on-change": val => {
                                    if (val == params.row.sort) return
                                    this.recommend = params.row.recommend
                                    this.sortNum = val
                                    this.infoId = params.row.id
                                    this.orgCode = params.row.orgCode
                                    clearTimeout(this.timer)
                                    this.timer = setTimeout(() => {
                                        this.sortChangeFn({
                                            pushChannel: params.row.pushChannel,
                                            status:
                                            params.row.status.indexOf("待审核") > -1  ?  '1' :
                                            params.row.status.indexOf("审核不通过") > -1  ?  '2' :
                                            params.row.status.indexOf("待发布") > -1  ?  '3' :
                                            params.row.status.indexOf("已发布") > -1  ?  '4' :
                                            params.row.status.indexOf("已过期") > -1  ?  '5' :
                                            params.row.status.indexOf("已归档") > -1  ?  '6' :
                                            ''
                                        })
                                    }, 1000)
                                },
                            },
                        })
                    },
                },
                {
                    title: "审核状态",
                    key: "status",
                    width: 120,
                    align: "center",
                },
                {
                    title: "编码",
                    key: "code",
                    width: 100,
                    align: "center",
                },
                {
                    title: "操作",
                    align: "center",
                    width: 180,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.activeId = params.row.id
                                                this.addChangeIden = false
                                                this.addChangeTitle = "修改问卷"
                                                this.changeActivity()
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1001" ? "" : "none",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.infoCode = params.row.code
                                                this.qrOCdeName = params.row.title
                                                this.$Message.loading({
                                                    background: true,
                                                    content: "正在加载数据，请稍等...",
                                                    duration: 0,
                                                })
                                                this.signInObj = {
                                                    id: params.row.id,
                                                    businessType: params.row.businessType,
                                                    code: params.row.code,
                                                }
                                                this.$get("/old/api/pc/information/getInformationScopeLevel", {
                                                    informId: params.row.id,
                                                })
                                                    .then(res => {
                                                        if (res.code == 200) {
                                                            this.scopeStatus = res.data
                                                            this.getOfficialScopeList(params.row.id, "2",'44')
                                                        } else {
                                                            this.$Message.destroy()
                                                            this.$Message.error({
                                                                background: true,
                                                                content: res.desc,
                                                            })
                                                        }
                                                    })
                                                    .catch(err => {
                                                        console.log(err)
                                                        this.$Message.destroy()
                                                        this.$Message.error({
                                                            background: true,
                                                            content: "获取数据失败，请联系管理员处理",
                                                        })
                                                    })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "二维码" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.activeId = params.row.id
                                                this.publishTitle = "审核问卷"
                                                this.infoStatus = params.row.status
                                                this.publishButtonName = "通过"
                                                this.publishStatus = true

                                                this.publicRecommend = ""
                                                this.publicRecommend = params.row.recommend

                                                this.orgCode = params.row.orgCode
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1002" && params.row.status == "待审核" ? "" : "none",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "审核" //1002
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.publishTitle = "发布问卷"
                                                this.activeId = params.row.id
                                                this.infoStatus = params.row.status
                                                this.publishButtonName = "发布"
                                                this.publishStatus = true
                                                this.publicRecommend = ""
                                                this.publicRecommend = params.row.recommend

                                                this.orgCode = params.row.orgCode
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1003" && params.row.status == "待发布" ? "" : "none",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "发布" //1003
                                ),
                                h(
                                    "Dropdown",
                                    {
                                        props: {
                                            transfer: true,
                                            // trigger: "click",
                                        },
                                    },
                                    [
                                        h(
                                            "span",
                                            {
                                                class: "operation-title",
                                                style: {
                                                    color: "#2d8cf0",
                                                    cursor: "pointer",
                                                    userSelect: "none",
                                                    position: "relative",
                                                    display: "flex",
                                                },
                                            },
                                            "操作"
                                        ),
                                        h(
                                            "DropdownMenu",
                                            {
                                                slot: "list",
                                            },
                                            [
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.publishTitle = "过期活动"
                                                                this.activeId = params.row.id
                                                                this.infoStatus = params.row.status
                                                                this.publishButtonName = "过期"
                                                                this.publishStatus = true

                                                                this.orgCode = params.row.orgCode
                                                            },
                                                        },
                                                        style: {
                                                            display: params.row.status == "已发布" ? "block" : "none",
                                                            textAlign: "center",
                                                        },
                                                    },
                                                    "过期"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.publishTitle = "归档活动"
                                                                this.activeId = params.row.id
                                                                this.infoStatus = params.row.status
                                                                this.publishButtonName = "归档"
                                                                this.publishStatus = true

                                                                this.orgCode = params.row.orgCode
                                                            },
                                                        },
                                                        style: {
                                                            display: params.row.status == "已过期" ? "block" : "none",
                                                            textAlign: "center",
                                                        },
                                                    },
                                                    "归档"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                // console.log(123)
                                                                this.activeId = params.row.id
                                                                this.addChangeTitle = "新增问卷"
                                                                this.addChangeIden = true
                                                                this.addType = "copy"
                                                                this.changeActivity()
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                            display: this.buttonRoot == "1001" ? "block" : "none",
                                                        },
                                                    },
                                                    "复制"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.$router.push({
                                                                    path: "/directionalairecollect",
                                                                    query: {
                                                                        id: params.row.id,
                                                                        menuId: this.$core.getUrlParam("menuId"),
                                                                    },
                                                                })
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                            display: params.row.status == "已发布" || params.row.status == "已过期" ? "" : "none",
                                                        },
                                                    },
                                                    "采集信息"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.infoId = params.row.id
                                                                this.$Modal.confirm({
                                                                    title: "温馨提示",
                                                                    content: "<p>您正在删除当前信息，此操作不可逆，是否继续</p>",
                                                                    onOk: () => {
                                                                        this.$post("/info/api/pc/information/v2/deleteById", {
                                                                            infoId: this.infoId,
                                                                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                                                                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                                                                            oemCode: parent.vue.oemInfo.oemCode,
                                                                            businessType: this.menuCodeObj.businessType,
                                                                            functionType: this.menuCodeObj.functionType,
                                                                            terminal: "2",
                                                                        }).then(res => {
                                                                            if (res.code == 200) {
                                                                                this.$Message.success({
                                                                                    background: true,
                                                                                    content: "删除成功",
                                                                                })
                                                                                this.hadlePageSize({
                                                                                    page: this.page,
                                                                                    pageSize: this.pageSize,
                                                                                })
                                                                            } else {
                                                                                this.$Message.success({
                                                                                    background: true,
                                                                                    content: res.desc,
                                                                                })
                                                                            }
                                                                        })
                                                                    },
                                                                })
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                        },
                                                    },
                                                    "删除"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.synchroniZationStatus = true
                                                                this.synchronId = params.row.id
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                        },
                                                    },
                                                    "更换栏目"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.setTemplate(params.row)
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                            display: this.isManager == "1" ? "block" : "none",
                                                        },
                                                    },
                                                    "设为模板"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.quesDate = params.row
                                                                this.relationStatus = true
                                                                this.showNum++
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                            display: params.row.status == "已发布" || params.row.status == "已过期" ? "" : "none",
                                                        },
                                                    },
                                                    "关联数据"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.infoCode = params.row.code
                                                                this.infoId = params.row.id
                                                                this.aboutLinkStatus = true
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                        },
                                                    },
                                                    "配置相关链接"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                //  this.$Modal.confirm({
                                                                //   title: "温馨提示",
                                                                //   content:
                                                                //     "<p>确认是否进行拟制机构推送操作？</p>",
                                                                //       onOk: () => {
                                                                //         this.$post('/datamsg/api/pc/information/v2/pushAccurateInformation',{
                                                                //           orgCodeList:params.row.orgCode,
                                                                //           informationId:params.row.id,
                                                                //           functionType:params.row.functionType
                                                                //         }).then(res=>{
                                                                //           if(res.code == 200){
                                                                //             this.$Message.success({
                                                                //               content:'操作成功',
                                                                //               background:true
                                                                //             })
                                                                //             this.hadlePageSize({
                                                                //               page: this.page,
                                                                //               pageSize: this.pageSize
                                                                //             });
                                                                //             return
                                                                //           }else{
                                                                //             this.$Message.error({
                                                                //               content:'操作失败',
                                                                //               background:true
                                                                //             })
                                                                //             return
                                                                //           }
                                                                //         })
                                                                //       }
                                                                //  })
                                                                this.pushObj.informationId = params.row.id
                                                                this.pushObj.orgCode = params.row.orgCode
                                                                this.pushObj.functionType = params.row.functionType
                                                                this.pushObj.orgName = params.row.source
                                                                this.pushObj.title = params.row.title.substring(0, 20)
                                                                this.pushStatus = true
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                            display: params.row.status == "已发布" ? "block" : "none",
                                                        },
                                                    },
                                                    "拟制机构推送"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.$router.push({
                                                                    path: "/notificationrecord",
                                                                    query: {
                                                                        path: this.$route.path,
                                                                        menuId: this.$route.query.menuId,
                                                                        id: params.row.id,
                                                                    },
                                                                })
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                        },
                                                    },
                                                    "通知记录"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.sendMessage = {
                                                                    businessType: params.row.businessType,
                                                                    infoId: params.row.id,
                                                                }
                                                                this.isOpenUserMessage = true
                                                                this.modelTitle = params.row.title
                                                                this.userChooseTle = true
                                                            },
                                                        },
                                                    },
                                                    "用户操作记录"
                                                ),
                                                // h(
                                                //   "DropdownItem",
                                                //   {
                                                //         nativeOn: {
                                                //           click: () => {
                                                //               this.exportExcelStatus = true
                                                //               this.exportList.id  = params.row.id
                                                //               this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
                                                //                   staffId: parent.vue.loginInfo.userinfo.id,
                                                //                   orgCode: parent.vue.loginInfo.userinfo.orgCode,
                                                //                 })
                                                //                   .then((res) => {
                                                //                     if (res.code == 200 && res.dataList && res.dataList.length > 0) {
                                                //                       this.communityList = res.dataList;
                                                //                     } else {
                                                //                       this.$Message["error"]({
                                                //                         background: true,
                                                //                         content: "获取社区数据失败！",
                                                //                       });
                                                //                     }
                                                //                   })
                                                //                   .catch((err) => {
                                                //                     this.$Message["error"]({
                                                //                       background: true,
                                                //                       content: "无法导入数据！",
                                                //                     });
                                                //                });
                                                //           }
                                                //         },
                                                //         style: {
                                                //           textAlign: "center",
                                                //         }
                                                //   },
                                                //   "导出"
                                                // ),

                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.contrastStatus = true
                                                                this.contrastShow = true
                                                                this.contrastInfoId = params.row.id
                                                                this.conBusinessType = params.row.businessType
                                                                this.conFunctionType = params.row.functionType
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                        },
                                                    },
                                                    "对比分析"
                                                ),
                                                // h(
                                                //   "DropdownItem",
                                                //   {
                                                //     nativeOn: {
                                                //       click: () => {
                                                //         this.changeRow = {
                                                //           id: params.row.id,
                                                //           businessType: params.row.businessType,
                                                //           orgCode: params.row.orgCode
                                                //         };

                                                //         this.ChangeColumn = true;
                                                //         this.getColumnTree();
                                                //       }
                                                //     },
                                                //     style: {
                                                //       textAlign: "center"
                                                //     }
                                                //   },

                                                //   "更换栏目"
                                                // ),

                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.infoCode = params.row.code
                                                                this.qrOCdeName = params.row.title
                                                                this.$Message.loading({
                                                                    background: true,
                                                                    content: "正在加载数据，请稍等...",
                                                                    duration: 0,
                                                                })
                                                                this.signInObj = {
                                                                    id: params.row.id,
                                                                    businessType: params.row.businessType,
                                                                    code: params.row.code,
                                                                }
                                                                this.$get("/old/api/pc/information/getInformationScopeLevel", {
                                                                    informId: params.row.id,
                                                                })
                                                                    .then(res => {
                                                                        if (res.code == 200) {
                                                                            this.scopeStatus = res.data
                                                                            this.getOfficialScopeList(params.row.id, "3")
                                                                        } else {
                                                                            this.$Message.destroy()
                                                                            this.$Message.error({
                                                                                background: true,
                                                                                content: res.desc,
                                                                            })
                                                                        }
                                                                    })
                                                                    .catch(err => {
                                                                        console.log(err)
                                                                        this.$Message.destroy()
                                                                        this.$Message.error({
                                                                            background: true,
                                                                            content: "获取数据失败，请联系管理员处理",
                                                                        })
                                                                    })
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                        },
                                                    },
                                                    "分享并预览"
                                                ),

                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.$router.push({
                                                                    path: "/readunread",
                                                                    query: {
                                                                        msgId: params.row.id,
                                                                        menuId: this.$core.getUrlParam("menuId"),
                                                                        type: "directional",
                                                                    },
                                                                })
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                            display: params.row.status == "已发布" || params.row.status == "已过期" ? "" : "none",
                                                        },
                                                    },
                                                    "已读未读"
                                                ),
                                            ]
                                        ),
                                    ]
                                ),
                            ]
                        )
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            total: 0,
            pageSize: 20,
            // 菜单数据
            menuCodeObj: {},
            //   新增/修改
            infoId: "",
            addChangeIden: false,
            addChangeTitle: "",
            addChangeStatus: false,
            cancelModalTip: false,

            // 查看
            seeStatus: false,

            // 三级审核
            buttonRoot: "",
            status: "",

            //   驳回/发布
            publishTitle: "",
            publishStatus: false,
            infoStatus: "",
            publishButtonName: "",

            // 公众号链接
            officialStatus: false,
            officialUrl: "",
            codeImgUrl: "",

            officialCity: "",
            officialCityList: [],
            officialRegion: "",
            officialRegionList: [],
            officialStree: "",
            officialStreeList: [],
            officialProject: [],
            officialProjectList: [],
            spinOfficialShow: false,
            signInObj: {},
            spinShow: false,
            // 相关链接
            aboutLinkStatus: false,
            infoCode: "",

            //排序定时器
            timer: null,
            //推荐/排序
            recommend: "",
            infoId: "",
            sortNum: "",

            // zhw新增
            ChangeColumn: false,
            cancelModelTip: false,
            changeRow: {},
            // 级联选择器的数据
            cascaderData: [],
            cascadValue: [],

            // 用户操作记录
            userChooseTle: false,
            cancelModelUser: false,
            modelTitle: "", //用户操作纪律弹窗标题
            sendMessage: {
                businessType: "",
                infoId: "",
            },
            isOpenUserMessage: false, //是否点击了用户操作按钮
            selectList: [],
            numFenxiStatus: false, //数据分析模态框
            infoIdLis: "",

            // 二维码
            qrOCdeName: "",
            qrCodeOrgName: "",
            codeImgUrl: "",
            changeVal: "",
            projectList: [],

            // 导出按钮弹窗
            exportExcelStatus: false,
            exportExcelTip: false,
            exportList: {
                id: "",
            },
            //excel导出弹窗  社区数据
            communityList: [],
            // 选择社区的code
            marital: "",
            isTrue: 0,

            // 对比分析
            contrastStatus: false,
            contrastInfoId: "",
            contrastShow: false,
            conFunctionType: "",
            conBusinessType: "",

            // publish的recommend按钮
            publicRecommend: "",

            // 短信链接
            noteUrl: "",
            noteCode: "",

            // 存储当前信息的orgCode
            orgCode: "",

            //模板
            templateStatus: false,
            formData: {},
            addType: "add",

            relationStatus: false,
            quesDate: {},
            showNum: 0,

            // 控制首页引用 范围回显，停留界面只允许当前第一次
            quoteNum: 0,

            // 设为模板按钮权限
            isManager: "",

            // 分享二维码图片列表
            qrimgList: [
                "/images/pic_hd1.png",
                "/images/pic_hd2.png",
                "/images/pic_hd3.png",
                "/images/pic_hd4.png",
                "/images/pic_hd5.png",
                "/images/pic_hd6.png"
            ],
            qrimgNo: "1",
            qrimg: "/images/pic_hd1.png",

            // 上传图片
            action: "",
            file: "",
            uploadData: {},
            imageName: "",
            imagePath: "",
            // 裁剪组件的基础配置option
            option: {
                img: "", // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: "jpg", // 裁剪生成图片的格式
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 600, // 默认生成截图框宽度
                autoCropHeight: 500, // 默认生成截图框高度
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [400, 565], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },
            cropperStatus: false,

            //推送
            pushStatus: false,
            pushObj: {},

            status: "",
            status1: 0,
            status2: 0,
            status3: 0,

            // 更换栏目
            synchroniZationStatus: false,
            synchroniZationStatusTip: false,
            synchronId: "",

            // 指定人员模态框
            personStatus: false,

            selectPersonList: [],
            personInfoId: "",

            textActive:false
        }
    },
    methods: {
        // 指定人员保存框
        savePerson() {
            if (this.$refs.person.save()) {
                this.personStatusStatusFn(false)
            }
        },
        // 指定人员模态框
        personStatusStatusFn(status) {
            this.personStatus = status
        },
        //左侧信息菜单
        selectMenu(name) {
            if (name == "7") {
                this.status = ""
            } else {
                this.status = name
            }
            this.page = 1
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
            this.selectList = []
        },
        //获取信息状态的数量
        messageStatusCount() {
            this.$get(
                // "/datamsg/api/pc/information/v2/groupStatus",
                "/info/api/pc/information/v2/groupStatus",
                {
                    dataScopeList: "",
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    oemCode: parent.vue.oemInfo.oemCode,
                    operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    businessType: this.menuCodeObj.businessType,
                    functionType: this.menuCodeObj.functionType,
                    type: this.menuCodeObj.columnType,
                    columnCode: this.menuCodeObj.columnCode,
                }
            ).then(res => {
                if (res.code == 200) {
                    this.status1 = res.data.auditNumber
                    this.status2 = res.data.rejectNumber
                    this.status3 = res.data.waitNumber
                }
            })
        },
        // 清空区码
        clearRegion() {
            // this.officialRegionList = []
            this.officialRegion = ""
            this.officialStree = ""
            this.officialProject = []
            this.qrCodeOrgName = ""
            this.changeVal = ""
        },
        // 点击清空街道按钮
        clearStree() {
            this.officialStree = ""
            this.officialProject = []
            this.makeOffDisabled = false
            this.qrCodeOrgName = ""
            this.changeVal = ""
        },
        // 关闭分析模态框
        contrastClose() {
            this.contrastShow = false
            this.contrastStatus = false
        },
        // 对比分析模态框关闭事件
        contrastFn(status) {
            this.contrastStatus = status
            // this.contrastShow = false
        },

        selectCommunity(code) {
            this.marital = code
        },
        // 导出弹窗关闭模态框事件
        exportExcelStatusFn(status) {
            if (!status && this.exportExcelTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.exportExcelStatus = status
                        this.exportExcelTip = false
                        this.marital = ""
                        this.exportList.id = ""
                    },
                })
            } else {
                this.exportExcelStatus = status
                this.exportExcelTip = false
            }
        },
        // 确认导出按钮
        canfirmExport() {
            if (this.marital == "") {
                this.$Message.warning({
                    content: "请选择社区",
                    background: true,
                })
                return
            } else {
                let data = {
                    id: this.exportList.id,
                    orgCode: this.marital,
                }
                this.exportBtn(data)
            }

            //  console.log(this.exportList.id)
            //  console.log(this.marital);
        },
        // 选择街道获取的数据
        changeProject(val) {
            this.projectList = val
            if (val && val.length > 0) {
                this.makeOffDisabled = true
            } else {
                this.makeOffDisabled = false
            }
        },
        // 点击下载宣传二维码
        mergeImage() {
            html2canvas(this.$refs.haibaoImg, { useCORS: true }).then(function(canvas) {
                let src = canvas.toDataURL()
                var image = new Image()

                // 解决跨域 Canvas 污染问题
                image.setAttribute("crossOrigin", "anonymous")
                image.onload = function() {
                    canvas.width = image.width
                    canvas.height = image.height
                    var context = canvas.getContext("2d")
                    context.drawImage(image, 0, 0, image.width, image.height)
                    var url = canvas.toDataURL("image/png", 1.0)
                    var a = document.createElement("a")
                    var event = new MouseEvent("click")
                    a.download = "公众号宣传二维码"
                    a.href = url
                    a.dispatchEvent(event)
                }
                image.src = src
            })
        },
        numFenxiFn(status) {
            this.numFenxiStatus = status
        },
        // 点击行为数据按钮
        openNumCount() {
            if (this.selectList.length == 0) {
                this.$Message.warning({
                    content: "请选择后进行点击",
                    background: true,
                })
                return
            } else {
                this.numFenxiStatus = true
                let List = []
                this.isTrue += 1
                this.selectList.map(item => {
                    List.push(item.id)
                })
                this.infoIdLis = List.join(",")
                console.log(this.infoIdLis)
            }
        },
        // 选择了什么
        tableSelect(val) {
            this.selectList = val
        },
        //排序
        sortChangeFn(obj) {
            let params = {
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                id: this.infoId, //当前信息类id
                staffName: parent.vue.loginInfo.userinfo.realName,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: this.orgCode,
                sort: this.sortNum,
                recommend: this.recommend,
                pushChannel: obj.pushChannel,
                status:obj.status
            }

            this.$post("/info/api/pc/information/v2/changeField", params)
                .then(res => {
                    if (res.code == 200) {
                        this.hadlePageSize({
                            page: this.page,
                            pageSize: this.pageSize,
                        })
                        this.$Message.success({
                            background: true,
                            content: "修改成功",
                        })
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "修改失败",
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        // 查看
        seeStatusFn(status) {
            this.seeStatus = status
        },
        // 查看跳去修改
        seeToChange() {
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            })
            this.seeStatus = false
            this.addChangeTitle = "修改问卷"
            this.addChangeStatus = true
        },
        // 相关链接
        aboutLinkStatusFn(status) {
            if (!status && this.cancelModalTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.aboutLinkStatus = status
                    },
                })
            } else {
                this.aboutLinkStatus = status
            }
        },
        aboutLinkSave() {
            this.$refs.aboutLink.save()
        },
        // 公众号链接
        makeOfficialQRCode() {
            if (!this.officialCity) {
                this.$Message.error({
                    background: true,
                    content: "请至少选择一个范围",
                })
                return
            }
            // if (!this.officialStree) {
            // this.$Message.error({
            //   background: true,
            //   content: "只能生成街道或社区的签到码，请选择街道"
            // });
            // return;
            // } else if (this.scopeStatus == "5" && this.officialProject.length == 0) {
            //   this.$Message.error({
            //     background: true,
            //     content: "该活动只发布给社区，请选择社区"
            //   });
            //   return;
            // }
            this.spinShow = true
            this.linkMake = true
            this.showMake = false

            let params = {}
            if (this.officialCity && this.officialCity != "") {
                params = {
                    cityCode: this.officialCity,
                }
                this.officialCityList.map(item => {
                    if (item.orgCode == this.officialCity) {
                        this.qrCodeOrgName = item.orgName
                    }
                })
            }
            if (this.officialRegion && this.officialRegion != "") {
                params = {
                    regionCode: this.officialRegion,
                }
                this.officialRegionList.map(item => {
                    if (item.orgCode == this.officialRegion) {
                        this.qrCodeOrgName = item.orgName
                    }
                })
            }
            if (this.officialStree && this.officialStree != "") {
                params = {
                    streetCode: this.officialStree,
                }
                this.officialStreeList.map(item => {
                    if (item.orgCode == this.officialStree) {
                        this.qrCodeOrgName = item.orgName
                    }
                })
            }
            if (this.officialProject.length > 0) {
                this.qrCodeOrgName = ""
                let data = []
                params = {
                    communityList: (() => {
                        let result = []
                        this.officialProjectList.map(item => {
                            if (this.officialProject.indexOf(item.orgCode) != -1) {
                                result.push({
                                    projectCode: item.mappingCode,
                                    projectName: item.orgName,
                                })
                            }
                        })
                        return result
                    })(),
                }
                params.communityList.map(item => {
                    data.push(item.projectName)
                })
                this.qrCodeOrgName = data.join(",")
            }
            this.$post(
                "/datamsg/api/pc/wxqr/createWxAppletQr",
                {
                    type: "ACTIVITY_SHARE",
                    value: JSON.stringify({
                        path: "pages/welcome/welcome",
                        redirectPath: "/mainTaskPages/modules/infoCollection/pages/detail/index",
                        params: {
                            ...params,
                            code: this.signInObj.code,
                            functionType: "32",
                            // recommendId: this.signInObj.id
                        },
                    }),
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200 && res.data) {
                    this.codeImgUrl = res.data.codeUrl
                    this.officialUrl = "pages/welcome/welcome?scene=" + res.data.code
                    this.noteCode = res.data.code
                    this.spinShow = false
                    // this.$post(
                    //   "/verifyapi/api/sytop/pc/urlLink/createUrlLink",
                    //   {
                    //     path:'pages/welcome/welcome',
                    //     query: `scene=${this.noteCode}`,
                    //   },
                    // ).then(res => {
                    //   if (res.code == 200 && res.data) {
                    //     this.noteUrl = res.data.link
                    //   }
                    // });
                }
            })
        },
        officialStatusFn(status) {
            if (!status) {
                this.spinOfficialShow = false
                this.codeImgUrl = ""
                this.officialUrl = ""
                this.scopeStatus = ""
                this.signInObj = ""
                this.officialRegion = ""
                this.officialRegionList = []
                this.officialStree = ""
                this.officialStreeList = []
                this.officialProject = []
                this.officialProjectList = []
                this.makeOffDisabled = false
            }
            this.officialStatus = status
        },
        getOfficialScopeList(informId, orgLevel, orgCodes) {
            if (!informId || (!orgCodes && (orgLevel == "4" || orgLevel == "5"))) return
            this.changeVal = orgCodes
            this.$get("/old/api/pc/information/getInformationScopeList", {
                informId: informId,
                orgLevel: orgLevel,
                orgCodes: orgCodes,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                       if (orgLevel == "2") {
                            this.officialCityList = res.dataList
                            this.officialRegionList = []
                            this.officialRegion = ""
                            this.officialStreeList = []
                            this.officialStree = ""
                            this.officialProject = []
                            this.officialProjectList = []
                            if (res.dataList.length == 1) {
                                this.officialCity = res.dataList[0].orgCode
                                this.getOfficialScopeList(informId, "3", this.officialCity)
                            }
                        } else  if (orgLevel == "3") {
                            if (res.dataList.length == 1) {
                                this.officialRegion = res.dataList[0].orgCode
                                this.getOfficialScopeList(informId, "4", this.officialRegion)
                            }
                            this.officialRegionList = res.dataList
                            this.officialStreeList = []
                            this.officialStree = ""
                            this.officialProject = []
                            this.officialProjectList = []
                        } else if (orgLevel == "4") {
                            if (res.dataList.length == 1) {
                                this.officialStree = res.dataList[0].orgCode
                                this.getOfficialScopeList(informId, "5", this.officialStree)
                            }
                            this.officialStreeList = res.dataList

                            this.officialProject = []
                            this.officialProjectList = []
                        } else {
                            if (res.dataList.length == 1) {
                                this.officialProject.push(res.dataList[0].orgCode)
                            }
                            this.officialProjectList = res.dataList
                        }
                        this.$Message.destroy()
                        this.officialStatus = true
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        downloadImgCode() {
            var image = new Image()
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous")
            image.onload = function() {
                var canvas = document.createElement("canvas")
                canvas.width = image.width
                canvas.height = image.height
                var context = canvas.getContext("2d")
                context.drawImage(image, 0, 0, image.width, image.height)
                var url = canvas.toDataURL("image/png") //得到图片的base64编码数据
                var a = document.createElement("a") // 生成一个a元素
                var event = new MouseEvent("click") // 创建一个单击事件
                a.download = "公众号推广二维码"
                a.href = url // 将生成的URL设置为a.href属性
                a.dispatchEvent(event) // 触发a的单击事件
            }
            image.src = this.codeImgUrl
        },
        copyShareCode() {
            var oInput = document.createElement("input")
            oInput.value = this.officialUrl
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = "oInput"
            oInput.style.display = "none"
            document.body.removeChild(oInput)
            this.$Message.success({
                background: true,
                content: "复制链接成功！",
            })
        },
        // 点击复制短信链接
        copyNoteUrl() {
            var oInput = document.createElement("input")
            oInput.value = this.noteUrl
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = "oInput"
            oInput.style.display = "none"
            document.body.removeChild(oInput)
            this.$Message.success({
                background: true,
                content: "复制链接成功！",
            })
        },
        // 发布/审核
        publishStatusFn(status) {
            if (this.$route.query.id && this.$route.query.menuId && this.$route.query.status && !status) {
                var index = parent.layer.getFrameIndex(window.name) //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.publishStatus = status
            }
        },
        reject() {
            this.$refs.publish.reject()
        },
        publish() {
            this.$refs.publish.publish()
        },
        // 通过并发布按钮
        passAndSend() {
            this.$refs.publish.passAndSend()
        },
        // 修改
        changeActivity() {
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            })
            this.addChangeStatus = true
        },
        reset() {
            this.searchData = { dataScopeList: [] }
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        search() {
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        // 新增/修改
        addChangeStatusFn(status) {
            if (this.$route.query.id && this.$route.query.menuId && this.$route.query.status && !status) {
                var index = parent.layer.getFrameIndex(window.name) //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else if (!status && this.cancelModalTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.addChangeStatus = status
                        this.addType = ""
                        this.quoteNum = 1
                        this.formData = {
                            terminal: ["1", "3", "4"],
                            time: (() => {
                                const end = new Date()
                                const start = new Date()
                                end.setTime(start.getTime() + 3600 * 1000 * 24 * 365 * 5)
                                return [start, end]
                            })(),
                            source: parent.vue.loginInfo.userinfo.orgName,
                            recommend: "0", //是否推荐,
                            pushChannel: false, //小程序推送通知
                        }
                    },
                })
            } else {
                this.addChangeStatus = status
            }
        },
        contentFn() {
            //预览文本
            this.$refs.addChange.contentFn()
        },
        contentFn1() {
            //预览文本
            this.$refs.publish1.contentFn()
        },
        save() {
            this.quoteNum = 1
            this.saveDisabled = true
            this.$refs.addChange.save()
        },
        // 新增修改,配置相关链接成功后执行
        saveSuccess() {
            this.templateStatus = false
            this.cancelModalTip = false
            this.addChangeStatus = false
            this.publishStatus = false
            this.aboutLinkStatus = false
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        hadlePageSize(obj) {
            this.loading = true
            this.$get(
                "/info/api/pc/information/v2/queryDataPage",
                // "/datamsg/api/pc/information/v2/queryDataPage",
                {
                    ...this.searchData,
                    dataScopeList: this.searchData.dataScopeList.length > 0 ? this.searchData.dataScopeList[this.searchData.dataScopeList.length - 1] : "",
                    oemCode: parent.vue.oemInfo.oemCode,
                    operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    businessType: this.menuCodeObj.businessType,
                    functionType: this.menuCodeObj.functionType,
                    columnCode: this.menuCodeObj.columnCode,
                    page: obj.page,
                    pageSize: obj.pageSize,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    terminal: "2",
                    status: this.status,
                }
            )
                .then(res => {
                    //获取信息状态的数量
                    this.messageStatusCount()
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.tableData = res.dataList.map(item => {
                            return {
                                orgCode: item.orgCode,
                                businessType: item.businessType,
                                title: item.title,
                                code: item.code,
                                releaseScope: item.releaseScope,
                                startTime: this.$core.formatDate(new Date(item.startTime), "yyyy-MM-dd hh:mm:ss"),
                                endTime: this.$core.formatDate(new Date(item.endTime), "yyyy-MM-dd hh:mm:ss"),
                                id: item.id,
                                sort: Number(item.sort),
                                source: item.source,
                                recommend: item.recommend,
                                status: (() => {
                                    switch (item.status) {
                                        case "1":
                                            return "待审核"
                                        case "2":
                                            return "审核不通过"
                                        case "3":
                                            return "待发布"
                                        case "4":
                                            return "已发布"
                                        case "5":
                                            return "已过期"
                                        case "6":
                                            return "已归档"
                                    }
                                })(),
                                authentication: item.ruleVos
                                    ? (() => {
                                          let str = ""
                                          item.ruleVos.map(subItem => {
                                              if (subItem.ruleCode == "authentication") {
                                                  if (subItem.ruleValue == "true") {
                                                      str = "是"
                                                  } else {
                                                      str = "否"
                                                  }
                                              }
                                          })
                                          return str
                                      })()
                                    : "",
                                createName: item.operationVo ? item.operationVo.createName : "",
                                functionType: item.functionType ? item.functionType : "",
                            }
                        })
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请重试",
                    })
                })
        },
        // 获取区
        getPulishData(code, list) {
            this.$get("/old/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.releaseScopeList = res.dataList.map(item => {
                        return {
                            value: item.orgPath,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                            loading: false,
                            children: [],
                        }
                    })
                }
            })
        },
        // 动态加载街道/社区
        loadCommunity(item, callback) {
            item.loading = true
            let arrCode = item.value.split("-")
            this.$get("/old/api/pc/staff/selectCascadeDataScope", {
                orgCode: arrCode[arrCode.length - 1],
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    if (!item.index) {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                loading: false,
                                children: [],
                                index: 1,
                            }
                        })
                    } else {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                index: 1,
                            }
                        })
                    }
                    item.loading = false
                    callback()
                }
            })
        },
        // zhw新增
        // 更换栏目
        // 点击关闭模态框的方法
        ChangeColumnFn(status) {
            console.log("获取模态框状态", status)
            if (!status && this.cancelModelTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                    onOk: () => {
                        this.ChangeColumn = status
                        this.cascadValue = []
                    },
                })
            } else {
                this.ChangeColumn = status
            }
        },
        // 更换栏目模态框保存按钮
        changeSave() {
            let menuId = ""
            if (this.cascadValue.length == 0) {
                this.$Message.warning({
                    content: "请选择后进行保存！",
                    background: true,
                })
                return
            } else {
                menuId = this.cascadValue.pop()
                console.log(menuId)
            }
            this.$post("/datamsg/api/pc/information/changeColumn", {
                businessType: this.changeRow.businessType,
                infoId: this.changeRow.id,
                adminType: parent.vue.loginInfo.userinfo.adminType,
                menuId: menuId,
            }).then(res => {
                if (res.code == 200) {
                    this.cancelModelTip = false
                    this.ChangeColumnFn(false)
                    this.hadlePageSize({
                        page: this.page,
                        pageSize: this.pageSize,
                    })
                    this.$Message.success({
                        content: "保存成功！",
                        background: true,
                    })
                    this.cascadValue = []
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        },
        getColumnTree() {
            this.$post(
                "/datamsg/api/pc/information/getChangeColumnMenuTree",
                {
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCode: this.changeRow.orgCode,
                    staffId: parent.vue.loginInfo.userinfo.id,
                    adminType: parent.vue.loginInfo.userinfo.adminType,
                    businessType: this.changeRow.businessType,
                    infoId: this.changeRow.id,
                }
                // { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    let data = res.dataList
                    this.cascaderData = this.forMatterTree(data)
                    console.log("递归修改value值后的结构树", this.cascaderData)
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                }
            })
        },
        // 格式化树结构，给树结构添加上value值和label值
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].title
                data[i].value = data[i].id
                if (data[i].children.length > 0 || !data[i].children) {
                    this.forMatterTree(data[i].children)
                }
            }
            return data
        },
        //用户操作关闭模态框操作
        UserChooseFn(status) {
            if (!status && this.cancelModelUser) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                    onOk: () => {
                        this.userChooseTle = status
                    },
                })
            } else {
                this.userChooseTle = status
            }
        },
        // 用户操作记录保存
        userSave(status) {
            this.userChooseTle = status
            this.isOpenUserMessage = false
        },
        // 导出按钮
        exportBtn(data) {
            this.$post("/datamsg/api/pc/dynamicform/statExportHouseOption", {
                formType: data.id,
                orgCode: data.orgCode,
            }).then(res => {
                if (res && res.code == 200) {
                    if (res.data) {
                        if (res.data.slice(0, 5) == "http") {
                            res.data = res.data.replace("http", "https")
                        }
                    }

                    let link = document.createElement("a")
                    link.href = res.data
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    this.$Message.destroy()
                    this.exportExcelStatus = false
                    this.exportExcelTip = false
                    this.marital = ""
                    this.exportList.id = ""
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        },

        changeTemplateStatus(val) {
            this.templateStatus = val
            if (!val) {
                this.$refs.Templatetable.templateName = ""
            }
        },
        // 设为模板
        setTemplate(data) {
            this.$post("/info/api/pc/information/template/addInformationTemplate", {
                infoId: data.id,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                terminal: "2",
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        },
        // 点击新增
        addQues() {
            this.templateStatus = true
            this.columnCode = this.menuCodeObj.columnCode
            this.$nextTick(() => {
                this.$refs.Templatetable.getList()
            })

            this.addChangeTitle = "新增问卷"
            this.activeId = ""
            this.addChangeIden = true
            // addChangeStatus = true;
        },
        setFormData(obj) {
            this.formData = obj
        },
        changeAddType(val) {
            console.log(val)
            this.addType = val
        },
        changeRelationStatus(val) {
            this.relationStatus = val
        },
        quoteData() {
            this.$nextTick(() => {
                this.$refs.addChange.quoteData()
            })
        },

        // 二维码背景选择
        changeQrimgNo(item, index) {
            this.qrimgNo = index + 1
            this.qrimg = `${item}`
            if(index >= 10){
                this.textActive = true
            }else{
                this.textActive = false
            }
        },

        // 上传之前
        beforeUpload(file) {
            return this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                if (res.code == 200) {
                    let now = this.$core.randomNumber()
                    //防止文件名有.
                    let suffixArr = file.name.split(".")
                    let suffix = "." + suffixArr[suffixArr.length - 1]
                    this.action = window.vue.getProxy()["/oss"].target
                    this.uploadData = {
                        name: now + suffix,
                        host: res.data.host,
                        key: res.data.key + now + suffix,
                        policy: res.data.encodedPolicy,
                        OSSAccessKeyId: res.data.accessId,
                        success_action_status: "200",
                        signature: res.data.postSignature,
                    }
                    this.imageName = now + suffix
                    this.imagePath = res.data.host + res.data.key + this.imageName
                }
            })
        },
        //图片上传成功
        imageSuccess(file) {
            if (file.status == "finished") {
                this.qrimgNo = ""
                this.qrimg = this.imagePath
                this.$Message["success"]({
                    background: true,
                    content: "图片上传成功！",
                })
            } else {
                this.$Message["error"]({
                    background: true,
                    content: "图片上传失败！",
                })
            }
        },
        // 图片格式错误
        imageError(file) {
            this.$Notice.warning({
                title: "上传文件格式错误！",
                desc: "文件 " + file.name + " 上传失败, 请上传jpg, jpeg, png或gif格式的图片！",
            })
        },
        // 图片上传进度
        imageProgress(event, file, fileList) {
            this.file = file
            event.target.onprogress = event => {
                let uploadPercent = parseFloat(((event.loaded / event.total) * 100).toFixed(2)) // 保留两位小数
                // 手动设置显示上传进度条 以及上传百分比
                this.file.showProgress = true
                this.file.percentage = uploadPercent
            }
        },
        // 图片移除
        imageRemove(file) {
            this.qrimg = ""
            this.file = {}
        },
        //取消裁剪
        cropperCancel(status) {
            this.cropperStatus = status
        },
        //打开裁剪
        async cropperFn() {
            if (!this.uploadData.host) {
                await this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                    if (res.code == 200) {
                        let now = this.$core.randomNumber()
                        //防止文件名有.
                        let suffixArr = this.qrimg.split(".")
                        let suffix = "." + suffixArr[suffixArr.length - 1]
                        this.action = window.vue.getProxy()["/oss"].target
                        this.uploadData = {
                            name: now + suffix,
                            host: res.data.host,
                            key: res.data.key + now + suffix,
                            policy: res.data.encodedPolicy,
                            OSSAccessKeyId: res.data.accessId,
                            success_action_status: "200",
                            signature: res.data.postSignature,
                        }
                        this.imageName = now + suffix
                        this.imagePath = this.uploadData.host + this.uploadData.key
                    }
                })
            }
            let _this = this
            this.setAvatarBase64(this.qrimg, base64 => {
                _this.option.img = base64
            })
            this.cropperStatus = true
        },
        //确定裁剪
        cropperFinish() {
            this.$refs.cropper.getCropBlob(data => {
                this.$refs.imgUpload.clearFiles()
                let formData = new FormData()
                formData.append("name", this.uploadData.name)
                formData.append("host", this.uploadData.host)
                formData.append("key", this.uploadData.key)
                formData.append("policy", this.uploadData.policy)
                formData.append("OSSAccessKeyId", this.uploadData.OSSAccessKeyId)
                formData.append("success_action_status", this.uploadData.success_action_status)
                formData.append("signature", this.uploadData.signature)
                formData.append("file", data)
                this.$post("https://liefeng.oss-cn-shenzhen.aliyuncs.com", formData, {
                    "Content-Type": "multipart/form-data",
                }).then(res => {
                    this.$Message.success({
                        background: true,
                        content: "裁剪完成",
                    })
                    this.qrimg = ""
                    this.$nextTick(() => {
                        this.qrimg = this.imagePath + "?" + new Date().getTime()
                        this.cropperCancel(false)
                    })
                })
            })
        },
        // 设置图片base64
        setAvatarBase64(src, callback) {
            let _this = this
            let image = new Image()
            // 处理缓存
            image.src = src + "?v=" + Math.random()
            // 支持跨域图片
            image.crossOrigin = "*"
            image.onload = function() {
                let base64 = _this.transBase64FromImage(image)
                callback && callback(base64)
            }
        },
        // 将网络图片转换成base64格式
        transBase64FromImage(image) {
            let canvas = document.createElement("canvas")
            canvas.width = image.width
            canvas.height = image.height
            let ctx = canvas.getContext("2d")
            ctx.drawImage(image, 0, 0, image.width, image.height)
            // 可选其他值 image/jpeg
            return canvas.toDataURL("image/png")
        },

        // 推送窗口
        changePushStatus(val) {
            this.pushStatus = val
        },

        // 推送
        customPush() {
            if (!this.pushObj.title.trim()) {
                this.$Message.warning({
                    content: "请输入通知内容",
                    background: true,
                })
                return
            }
            this.$post("/gateway/syinfopublish/api/pc/information/v2/customPush", {
                ...this.pushObj,
                publish: true,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "推送成功",
                        background: true,
                    })
                    this.pushStatus = false
                } else {
                    this.$Message.error({
                        content: "推送失败",
                        background: true,
                    })
                }
            })
        },

        // 更换栏目确认按钮事件
        async saveSynchroniZation() {
            let list = this.$refs.Synchron.$refs.tree.getCheckedNodes().filter(item => item.columnCode)
            console.log("list", list)
            if (!list || !list.length) {
                this.$Message.warning({
                    content: "请勾选一个栏目",
                    background: true,
                })
            } else {
                this.$post(
                    "/info/api/pc/information/v2/changeColumn",
                    {
                        informationId: this.synchronId,
                        columnCode: list[list.length - 1].columnCode,
                        subColumnCode: list[list.length - 1].subColumnCode,
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "更换栏目成功",
                            background: true,
                        })
                        this.synchroniZationStatus = false
                        this.synchroniZationStatusTip = false
                        this.hadlePageSize({
                            page: this.page,
                            pageSize: this.pageSize,
                        })
                    } else {
                        this.$Message.error({
                            content: "更换栏目失败",
                            background: true,
                        })
                    }
                })
            }
        },
        synchroniZationStatusFn(status) {
            if (!status && this.synchroniZationStatusTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.synchroniZationStatus = status
                    },
                })
            } else {
                this.synchroniZationStatus = status
            }
        },
    },
    async created() {
        let userButtonRoot = parent.vue.userButtonRoot
        this.isManager = userButtonRoot.includes("INFORMATION:TEMPLATE_ADD") ? "1" : "2"
        console.log(this.isManager)
        this.buttonRoot = userButtonRoot.includes("1001") ? "1001" : userButtonRoot.includes("1002") ? "1002" : userButtonRoot.includes("1003") ? "1003" : ""
        // this.buttonRoot = "1003";
        this.status = this.buttonRoot == "1001" ? "2" : this.buttonRoot == "1002" ? "1" : this.buttonRoot == "1003" ? "3" : ""
        this.activeMenu = this.status
        // 获取functionType/columnCode等
        await this.$get("/old/api/pc/menu/selectColumnByMenuCode", {
            menuCode: this.$core.getUrlParam("menuId"),
        }).then(res => {
            if (res.code == 200 && res.data) {
                this.menuCodeObj = res.data
            } else {
                this.$Message.error({
                    background: true,
                    content: res.desc,
                })
            }
        })
        if (this.$route.query.templateId) {
            await this.$get("/info/api/pc/information/template/selectInformationTemplate", {
                staffId: parent.vue.loginInfo.userinfo.id,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                templateId: this.$route.query.templateId,
            }).then(res => {
                if (res.code == 200) {
                    let obj = JSON.parse(res.data.dataJson)
                    this.setFormData(obj), this.changeAddType("quote")
                    this.addChangeStatusFn(true)
                    this.columnCode = this.menuCodeObj.columnCode
                    this.addChangeTitle = "新增问卷"
                    this.activeId = ""
                    this.addChangeIden = true
                    this.$nextTick(() => {
                        this.$refs.addChange.quoteData()
                    })
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        }
        // 处理首页发布——跳审核发布
        if (this.$route.query.id && this.$route.query.menuId && this.$route.query.status) {
            switch (this.$route.query.status) {
                case "1":
                    this.publishTitle = "审核活动"
                    this.infoStatus = "待审核"
                    this.publishButtonName = "通过"
                    this.publishStatus = true
                    break
                case "2":
                    this.addChangeIden = false
                    this.publishTitle = "修改活动"
                    this.changeActivity()
                    break
                case "3":
                    this.publishTitle = "发布活动"
                    this.infoStatus = "待发布"
                    this.publishButtonName = "发布"
                    this.publishStatus = true

                    break
                case "4":
                    this.publishTitle = "过期活动"
                    this.infoStatus = "已发布"
                    this.publishButtonName = "过期"
                    this.publishStatus = true
                    break
            }
            this.activeId = this.$route.query.id
        }
        // 获取发布范围  releaseScopeList
        // 获取广州市的区
        this.getPulishData(4401)
        this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize,
        })
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
        AddChange,
        Publish,
        AboutLink,
        CheckRecord,
        Analysis,
        contrast,
        Templatetable,
        Relation,
        Synchron,
        Person,
    },
    filters: {
        spliceTitle(option) {
            if (option.length > 22) {
                return (option = option.slice(0, 22) + "...")
            } else {
                return option
            }
        },
    },
}
</script>

<style lang="less">
.operation-title {
    &::after {
        content: ">";
        position: "absolute";
        transform: rotate(90deg);
        display: block;
        margin-left: 2px;
        font-size: 10px;
    }
}
</style>
<style scoped lang="less">
// /deep/#toolsbarRight {
//   width: 1000%;
//   .search {
//     width: 100%;
//     text-align: right;
//     .ivu-form-item {
//       margin-bottom: 0;
//     }
//   }
// }
/deep/.ivu-radio-group {
    width: 100% !important;
}
// 截图
.cropper-content {
    .cropper {
        width: 580px;
        height: 428px;
    }
}
.diyimg {
    position: absolute;
    right: 162px;
    bottom: 286px;
    width: 380px;
    height: 180px;
    .imglist {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 310px;

        img {
            width: 60px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
        }
        .active {
            // border: 2px solid red;
            box-shadow: 0 0 5px blue;
            box-sizing: border-box;
        }
    }
}
.diyimg-right{
    right: 131px;
}
/deep/.ivu-modal-close {
    display: none;
}
.demo-spin-icon-load {
    animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
    from {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.signInOut {
    /deep/ #modal_contentarea {
        // width: 1146px !important;
    }
    /deep/.ivu-modal-close {
        display: block;
    }
}
.qrdiv {
    position: relative;
    .qrcode {
        position: relative;
        width: 400px;
        height: 565px;
        background: url("/images/pic_hd1.jpg") no-repeat;
        background-size: 100% 100% !important;
        img {
            position: absolute;
            top: 235px;
            left: 90px;
            width: 220px;
        }

        .h1h3 {
            width: 260px;
            height: 119px;
            // background-color: #333333;
            // color: #ff0000;
            color: #333;
            // width: 100%;
            position: absolute;
            top: 75px;
            left: 70px;
            text-align: center;
            h1 {
                width: 59%;
                margin: 0 auto;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
        .fontsize1 {
            width: 100%;
            font-size: 26px;
            line-height: 119px;
            word-break: break-all;
        }
        .fontsize2 {
            width: 100%;
            box-sizing: border-box;
            font-size: 24px;
            line-height: 33px;
            word-break: break-all;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .fontsize3 {
            width: 100%;
            font-size: 20px;
            word-break: break-all;
            line-height: 30px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .fontactive{
            margin-top: 50px;
        }
        h1 {
            text-align: left;
            font-size: 26px;
            color: red;
            margin-bottom: 10px;
            line-height: 36px;
        }
        h3 {
            padding: 0 30px;
            text-align: center;
            font-size: 26px;
            color: #c62e23;
        }
        p {
            width: 100%;
            position: absolute;
            top: 201px;
            text-align: center;
            font-size: 15px;
            color: #666666;
        }
        span {
            width: 70%;
            position: absolute;
            text-align: center;
            top: 470px;
            font-size: 18px;
            color: #333;
            margin: 0 15%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .qrcode-antoer{
        width: 334px;
        height: 544px;
        .h1h3 {
            left: 35px;
        }
        img{
            left: 55px;
        }
    }
}
.ivu-poptip {
    background: black;
    padding: 0 4px;
    border-radius: 50%;
    .ivu-icon-md-help {
        color: #fff;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
/deep/.ivu-tooltip-inner {
    width: 200px;
    white-space: normal;
}
/deep/.ivu-menu-item {
    z-index: 3;
}
</style>
