<template>
    <div class="container">
        <!-- 背景图片裁剪弹窗 -->
        <LiefengModal @input="cropperCancel" width="600px" height="500px" :value="cropperStatus">
            <template v-slot:contentarea>
                <Mymodel :fullscreen="false" :value="toggle1" :toggle="true" :textObj="textObj" title="文本预览" :toggle-fn2="toggleFn2"></Mymodel>
                <div class="cropper-content" v-if="cropperStatus">
                    <div class="cropper" style="text-align: center">
                        <vueCropper
                            ref="cropper"
                            :img="option.img"
                            :outputSize="option.size"
                            :outputType="option.outputType"
                            :info="true"
                            :full="option.full"
                            :canMove="option.canMove"
                            :canMoveBox="option.canMoveBox"
                            :original="option.original"
                            :autoCrop="option.autoCrop"
                            :fixed="option.fixed"
                            :fixedNumber="option.fixedNumber"
                            :centerBox="option.centerBox"
                            :infoTrue="option.infoTrue"
                            :fixedBox="option.fixedBox"
                        ></vueCropper>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="cropperCancel(false)" style="margin-right: 10px">取消</Button>
                <Button type="primary" @click="cropperFinish">裁剪</Button>
            </template>
        </LiefengModal>
        <div class="menu">
            <Menu theme="light" :active-name="showMenu" @on-select="selectMenu">
                <MenuItem name="baseInfo">
                    <Icon type="ios-information-circle" />
                    基本信息
                </MenuItem>
                <MenuItem name="writeInfo">
                    <Icon type="ios-create" />
                    填写表单设置
                </MenuItem>
                <MenuItem name="moreSetting">
                    <Icon type="md-chatbubbles" />
                    更多设置
                </MenuItem>
            </Menu>
        </div>
        <div class="content">
            <Form :model="formData" :label-colon="true" :label-width="120" v-show="showMenu == 'baseInfo'">
                <FormItem>
                    <span slot="label" class="validate">标题:</span>
                    <Input v-model.trim="formData.title" :maxlength="100"></Input>
                </FormItem>
                <FormItem label="封面图片">
                    <span slot="label" class="validate">封面图片:</span>
                    <img ref="image" :src="formData.photo" alt="" width="200" style="display: block; margin-bottom: 10px" />
                    <Progress v-if="file.showProgress" :percent="file.percentage" :stroke-width="5"></Progress>
                    <Upload
                        ref="imgUpload"
                        :before-upload="beforeUpload"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        style="display: inline-block"
                        :on-format-error="imageError"
                        :on-progress="imageProgress"
                        :on-success="(value, file) => imageSuccess(file)"
                        :action="action"
                        :data="uploadData"
                        :on-remove="imageRemove"
                        :show-upload-list="false"
                    >
                        <Button icon="ios-cloud-upload-outline" type="info">上传图片</Button>
                    </Upload>
                    <Button @click="selectTemplate" style="margin-left: 10px">选择模板</Button>
                    <Button v-if="formData.photo" type="success" @click="cropperFn" style="margin-left: 10px">裁剪图片</Button>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">公布时间:</span>
                    <DatePicker :options="dateOption" :transfer="true" v-model="formData.time" type="datetimerange" style="width: 400px" placeholder="请选择公布开始时间和结束时间"></DatePicker>
                    <Tooltip placement="top" :delay="500">
                        <Icon type="md-help-circle" style="margin: 10px"></Icon>
                        <div slot="content">
                            <p>到了公布开始时间，终端上才可查看到；</p>
                            <p>如果后台发布了，没有到开始时间，终端上也查看不到</p>
                        </div>
                    </Tooltip>
                </FormItem>

                <FormItem>
                    <span slot="label" class="validate">发布终端:</span>
                    <CheckboxGroup v-model="formData.terminal" @on-change="allSelect">
                        <Checkbox label="0">全部</Checkbox>
                        <Checkbox label="1">TV</Checkbox>
                        <!-- <Checkbox label="2">PC</Checkbox> -->
                        <Checkbox label="3">APP</Checkbox>
                        <Checkbox label="4">小程序</Checkbox>
                        <!-- <Checkbox label="5">公众号</Checkbox>
             <Tooltip placement="top" :delay="500">
              <Icon type="md-help-circle" style="margin: 10px"></Icon>
              <div slot="content">
                <p>
                  勾选公众号，则会对发布范围中关注过社区随约服务网上驿站公众号的用户主动推送此活动。
                </p>
              </div>
            </Tooltip> -->
                    </CheckboxGroup>
                </FormItem>
                <FormItem label="发布范围">
                    <span slot="label" class="validate">发布范围</span>
                    <Select v-model="city" transfer style="width: 25%" :max-tag-count="3" placeholder="请选择市" multiple filterable @on-change="changeCity">
                        <Option :value="item.value" v-for="(item, index) in cityList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="region" transfer style="width: 25%" :max-tag-count="3" placeholder="请选择区" :disabled="regionDisabled" multiple filterable @on-change="changeRegion">
                        <Option :value="item.value" v-for="(item, index) in regionList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="stree" transfer style="width: 25%" :max-tag-count="3" placeholder="请选择街道/乡镇" :disabled="streeDisabled" multiple filterable @on-change="changeStree">
                        <Option :value="item.value" v-for="(item, index) in streeList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="project" transfer style="width: 25%" :max-tag-count="3" placeholder="请选择社区" :disabled="projectDisabled" multiple filterable>
                        <Option :value="item.value" v-for="(item, index) in projectList" :key="index">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="联系电话">
                    <Input v-model.trim="formData.contactMobile" style="width: 200px"></Input>
                    <span style="color: #aaa; width: 200px; margin-left: 16px">说明：移动端可以直接拨打</span>
                </FormItem>

                <!-- <iframe
          name="ueditor"
          id="ueditor"
          src="/ueditor/index.html?titlename=活动详情（必填）"
          style="width: 100%; height: 500px; border: 0px"
          @load="loadUeditor"
        ></iframe> -->
                <iframe name="ueditor" id="ueditor" src="/mobileeditor/#/index?titlename=活动详情（必填）" style="width: 100%; height: 500px; border: 0px" @load="loadUeditor"></iframe>
            </Form>
            <Form :model="formData" v-show="showMenu == 'writeInfo'">
                <FormItem style="margin: 0 0 12px 10px; display: inline-block" v-if="addChangeIden">
                    <Button type="primary" @click="openFormTemp">引用模板</Button>
                </FormItem>
                <FormItem style="margin: 0 0 12px 10px; display: inline-block">
                    <Button type="primary" @click="openOnlyPreview">预览</Button>
                </FormItem>
                <!-- 表单模板 -->
                <div style="margin: 0 10px">
                    <AddForm v-if="changeFormShow === false" :hiddenTitle="true" ref="addChangeForm" :formDetailVo2="formDetailVo"></AddForm>
                    <ChangeForm v-if="changeFormShow === true" :hiddenTitle="true" :infoId="infoId" :formDetailVo2="formDetailVo" ref="addChangeForm"></ChangeForm>
                </div>
            </Form>
            <Form :model="formData" v-show="showMenu == 'moreSetting'" :label-colon="true" :label-width="150">
                <FormItem>
                    <span slot="label" class="validate">信息来源:</span>
                    <Input v-model="formData.source"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">是否可代填报:</span>
                    <RadioGroup :value="ruleVos[3].ruleValue" @on-change="changeruleVos3">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem v-if="ruleVos[3].ruleValue == '1'">
                    <span slot="label" class="validate">代报填写信息:</span>
                    <Select v-model="ruleVos[4].ruleValue" style="width: 200px">
                        <Option value="1">姓名及手机号</Option>
                        <Option value="2">姓名、手机号及身份证号</Option>
                    </Select>
                </FormItem>
                <FormItem label="按钮名称">
                    <Select :value="ruleVos[2].ruleValue" style="width: 200px" transfer @on-change="changeRuleVos2">
                        <Option value="立即报名">立即报名</Option>
                        <Option value="立即填报">立即填报</Option>
                        <Option value="立即参与">立即参与</Option>
                        <Option value="立即预约">立即预约</Option>
                        <Option value="其他">其他</Option>
                    </Select>
                    <span style="color: #aaa; margin-left: 16px">注：用户参与的时候看到的按钮名称</span>
                </FormItem>
                <FormItem v-if="ruleVos[2].ruleValue == '其他'">
                    <span slot="label" class="validate">名称:</span>
                    <Input v-model="formData.buttonName" :maxlength="10" placeholder="限10字" style="width: 200px"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">填报用户签名确认:</span>
                    <RadioGroup :value="ruleVos[5].ruleValue" @on-change="changeruleVos4">
                        <Radio label="1">需签名</Radio>
                        <Radio label="0">不用签名</Radio>
                    </RadioGroup>
                    <span style="color: rgb(170, 170, 170); margin-left: 16px">注：用户填写后需要在线签名确认</span>
                </FormItem>

                <FormItem>
                    <span slot="label" class="validate">限制每人限填一次:</span>
                    <RadioGroup :value="ruleVos[6].ruleValue" @on-change="changeruleVos5">
                        <Radio label="1">限制</Radio>
                        <Radio label="0">不限制</Radio>
                    </RadioGroup>
                    <span style="color: rgb(170, 170, 170); margin-left: 16px">注：每人只可填写1次，不会有重复数据，适用于严格限制参与人数的情况</span>
                </FormItem>

                <FormItem>
                    <span slot="label" class="validate">限制填写人修改结果:</span>
                    <RadioGroup :value="ruleVos[7].ruleValue" @on-change="changeruleVos6">
                        <Radio label="1">限制</Radio>
                        <Radio label="0">不限制</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">是否推荐:</span>
                    <RadioGroup v-model="formData.recommend">
                        <Radio label="1">推荐</Radio>
                        <Radio label="0">不推荐</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem>
                    <span slot="label">限制填写次数：</span>
                    <InputNumber placeholder="限制表单总填写数" v-model="ruleVos[8].ruleValue" style="width: 200px" :max="9999999999" :min="1"></InputNumber>
                </FormItem>
                <!-- <FormItem>
          <span slot="label" >推送方式:</span>
          <Checkbox v-model="formData.pushChannel" >小程序订阅通知</Checkbox>
        </FormItem> -->

                <FormItem label="小程序订阅通知">
                    <CheckboxGroup v-model="pushType" @on-change="changePushType">
                        <Checkbox label="1">我的用户</Checkbox>
                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的服务过的居民可收到推送信息。</span>
                        <br />
                        <Checkbox label="2">我的管理员</Checkbox>
                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的员工档案在职管理员可收到推送信息。</span>
                        <br />
                        <Checkbox label="3">我的畅联组</Checkbox>
                        <span v-if="pushType && pushType[0] == 3">
                            <Input style="width: 300px; margin-left: 16px" v-model="formData.groupNames" />
                            <Button type="primary" @click="groupStatus = true">选群</Button>
                        </span>
                        <br />
                        <Checkbox label="4" v-if="dimensionId == 2 || this.buttonRoot == '1003'">社区线上用户（原推送功能）</Checkbox>
                        <span v-if="dimensionId == 2 || this.buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的所有线上居民可收到推送信息。</span>
                    </CheckboxGroup>
                </FormItem>

                <FormItem label="参与获得积分">
                    <InputNumber style="width: 200px" :max="999999999" :min="1" v-model="ruleVos[1].ruleValue"></InputNumber>
                </FormItem>
                <FormItem>
                    <!-- <span slot="label" class="validate">身份校验:</span>
          <RadioGroup v-model="ruleVos[0].ruleValue">
                <Radio label="1">
                    实名认证 <span style="color: #aaa; margin-left: 16px"
                      >注：勾选后可获填报用户的姓名、性别、年龄、手机号、身份证号，已认证的用户无需重复认证。</span
                    >
                </Radio>
                <Radio label="2">
                    网上家园认证
                    <span style="color: #aaa; margin-left: 16px"
                      >注：勾选后可获填报用户的姓名、手机号，已认证的用户无需重复认证</span
                    >
                </Radio>
                <Radio label =''> 
                    不认证 
                     <span style="color: #aaa; margin-left: 16px"
                      >注：不认证则只可获得报名用户的手机号</span
                    >
                </Radio>
          </RadioGroup> -->
                    <Checkbox v-model="ruleVos[0].ruleValue">实名认证</Checkbox>
                    <span style="color: #aaa; margin-left: 16px">注：勾选后可获填报用户的姓名、性别、年龄、手机号、身份证号，不勾选则只可获得报名用户的手机号</span>
                </FormItem>
            </Form>
        </div>
        <LiefengModal :value="formTemplateStatus" @input="formTemplateStatusFn" :fullscreen="true" title="模板库">
            <template v-slot:contentarea>
                <Tabs type="card" @on-click="toggleTab" class="tempContainer">
                    <TabPane label="全部模板">
                        <div class="tempBox">
                            <span v-if="formTemplateDate.length == 0" class="noList">暂无模板，请先添加</span>
                            <Card v-for="(item, index) in formTemplateDate" :key="index" v-else>
                                <p slot="title">{{ item.formName }}</p>
                                <p>创建：{{ item.createName }}</p>
                                <p>创建时间：{{ item.gmtCreate }}</p>
                                <p>共{{ item.fieldNum }}题</p>
                                <div style="text-align: right">
                                    <Button style="margin-right: 10px" type="primary" @click="recommend(item.id)">引用</Button>
                                    <Button type="success" @click="previewForm(item.id)">预览</Button>
                                </div>
                            </Card>
                        </div>
                        <Page style="float: right; margin-right: 8px" :total="total" show-total @on-change="changePage" />
                    </TabPane>
                    <TabPane label="个人模板">
                        <div class="tempBox">
                            <span v-if="formTemplateDate.length == 0" class="noList"><i>暂无模板，请先添加</i></span>
                            <Card v-for="(item, index) in formTemplateDate" :key="index" v-else>
                                <p slot="title">{{ item.formName }}</p>
                                <p>创建：{{ item.createName }}</p>
                                <p>创建时间：{{ item.gmtCreate }}</p>
                                <p>共{{ item.fieldNum }}题</p>
                                <div style="text-align: right">
                                    <Button style="margin-right: 10px" type="primary" @click="recommend(item.id)">引用</Button>
                                    <Button type="success" @click="previewForm(item.id)">预览</Button>
                                </div>
                            </Card>
                        </div>
                        <Page style="float: right; margin-right: 8px" :total="total" show-total @on-change="changePage" />
                    </TabPane>
                </Tabs>
            </template>
        </LiefengModal>
        <!-- 查看预览 -->
        <LiefengModal :title="previeswTitle" :fullscreen="true" :value="previewStatus" @input="previewStatusFn">
            <template v-slot:contentarea>
                <PreviewForm :previewFormData="previewFormData" v-if="previewStatus"></PreviewForm>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" @click="previewStatus = false">关闭</Button>
            </template>
        </LiefengModal>
        <!-- 编辑预览 -->
        <!-- 编辑表单预览 -->
        <LiefengModal title="表单预览" :fullscreen="true" :value="onlyPreviewStatus" @input="onlyPreviewStatusFn">
            <template v-slot:contentarea>
                <PreviewForm :onlyPreview="true" :activeMainList="activeMainList" v-if="onlyPreviewStatus"></PreviewForm>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" @click="onlyPreviewStatus = false">关闭</Button>
            </template>
        </LiefengModal>

        <!-- 图片模板 -->
        <LiefengModal @input="changeImgStatus" title="模板图片" width="868px" height="600px" :value="imgStatus">
            <template v-slot:contentarea>
                <div class="imgbox">
                    <div @click="selectImg(item.filePath)" class="imgitem" v-for="item in imgTemplateList" :key="item.index">
                        <img :src="item.filePath" alt="" />
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right: 10px" @click="changeImgStatus(false)">取消</Button>
            </template>
        </LiefengModal>

        <!-- 推送选群 -->
        <LiefengModal :value="groupStatus" title="选择畅联组" :fullscreen="true" @input="changeGroupStatus">
            <template v-slot:contentarea>
                <CheckGroup :buttonRoot="buttonRoot" ref="checkGroup" @getGroup="getGroup"></CheckGroup>
            </template>
            <template v-slot:toolsbar>
                <!-- <Button type="info" @click="cancelGroup" style="margin-right:10px"> 取消 </Button> -->
                <Button type="primary" @click="saveGroup">保存</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengEditor from "@/components/LiefengEditor"
import AddForm from "@/views/form/childrens/addForm"
import ChangeForm from "@/views/form/childrens/changeForm"
import LiefengModal from "@/components/LiefengModal"
import PreviewForm from "@/views/form/childrens/previewForm"
import { VueCropper } from "vue-cropper"
import Mymodel from "@/views/activity/Mymodel"
import CheckGroup from "./checkgroup.vue"

export default {
    // props: ["menuCodeObj", "activeId", "addChangeIden"],
    props: {
        buttonRoot: "",
        formData: {},
        menuCodeObj: {},
        activeId: "",
        addChangeIden: "",
        addType: "",
        quoteNum: "",
    },
    data() {
        return {
            pushType: [],
            dimensionId: "",
            groupStatus: false,
            // groupNames:'',
            // checkGroup:[],
            //富文本预览
            toggle1: false, //
            textObj: {
                spinShow: false,
                signInUrl: "", //预览二维码url
            },
            // 裁剪组件的基础配置option
            option: {
                img: "", // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: "jpg", // 裁剪生成图片的格式
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 600, // 默认生成截图框宽度
                autoCropHeight: 500, // 默认生成截图框高度
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [100, 69], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },
            cropperStatus: false,

            showMenu: "baseInfo",
            // formData: {
            //   terminal: ["1", "3", "4"],
            //   time: (() => {
            //     const end = new Date();
            //     const start = new Date();
            //     end.setTime(start.getTime() + 3600 * 1000 * 24 * 365);
            //     return [start, end];
            //   })(),
            //   source: parent.vue.loginInfo.userinfo.orgName,
            //   recommend: '0',  //是否推荐,
            //   pushChannel: false, //小程序推送通知
            // }, //新增修改时提交的数据第一层
            cycleDates: [], //重复内容--日期 / 周
            cycleDateList: (() => {
                let arr = []
                for (let i = 1; i < 32; i++) {
                    arr.push({
                        value: i,
                        label: i,
                    })
                }
                return arr
            })(),
            cycleTimeList: [
                { value: 1, label: "周一" },
                { value: 2, label: "周二" },
                { value: 3, label: "周三" },
                { value: 4, label: "周四" },
                { value: 5, label: "周五" },
                { value: 6, label: "周六" },
                { value: 7, label: "周日" },
            ],
            cycleTimes: [
                {
                    limits: [
                        {
                            attrType: "person",
                            attrValue: "",
                        },
                    ],
                    timeValue: [],
                },
            ], //开展时间
            dateOption: {
                shortcuts: [
                    //公布时间配置项
                    {
                        text: "1天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1)
                            return [start, end]
                        },
                    },
                    {
                        text: "7天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
                            return [start, end]
                        },
                    },
                    {
                        text: "30天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
                            return [start, end]
                        },
                    },
                    {
                        text: "365天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 365)
                            return [start, end]
                        },
                    },
                ],
            },
            // 发布终端临时变量
            terminalList: [],
            dataScopeIdList: [], //发布范围列表
            city: [],
            region: [],
            stree: [],
            project: [],
            cityList: [],
            regionList: [],
            streeList: [],
            projectList: [],

            regionDisabled: true,
            streeDisabled: true,
            projectDisabled: true,
            // 上传图片
            action: "",
            file: "",
            imageName: "",
            imagePath: "",
            uploadData: {},
            ruleVos: [
                //规则
                {
                    ruleCode: "authentication", //实名认证
                    ruleValue: "",
                },
                {
                    ruleCode: 'participate_integral"', //参与获得积分
                    ruleValue: 1,
                },
                {
                    ruleCode: "button_name", //按钮名称
                    ruleValue: "立即填报",
                },
                {
                    ruleCode: "whether_registration", //代报名
                    ruleValue: "0",
                },
                {
                    ruleCode: "registration_type", //代报填写信息
                    ruleValue: "",
                },
                {
                    ruleCode: "signature", //填报用户签名确认
                    ruleValue: "0",
                },
                {
                    ruleCode: "submit_only", //限制每人限填一次
                    ruleValue: "0",
                },
                {
                    ruleCode: "modify_results",
                    ruleValue: "0",
                },
                {
                    ruleCode: "join_number_limit",
                    ruleValue: null,
                },
            ],
            // 模板弹窗
            formTemplateStatus: false,
            formTemplateDate: [],
            page: 1,
            pageSize: 9,
            total: 0,
            tabName: 0,
            changeFormShow: false, //控制显示新增还是修改表单组件
            infoId: "",
            formDetailVo: {}, //切换菜单时暂存模板表单数据

            // 预览
            previewStatus: false,
            previewFormData: {},
            previeswTitle: "",

            // 保存校验必填项
            validateArr: [
                {
                    text: "基本信息———标题不能为空",
                    value: "title",
                },
                {
                    text: "基本信息———公布时间不能为空",
                    value: "time",
                },
                {
                    text: "基本信息———请选择发布终端",
                    value: "terminal",
                },
                {
                    text: "基本信息———请选择发布范围",
                    value: "city",
                },
                {
                    text: "基本信息———请上传封面图片",
                    value: "photo",
                },
                {
                    text: "基本信息———活动详情不能为空",
                    value: "content",
                },
                {
                    text: "信息来源———请填写信息来源",
                    value: "source",
                },
                {
                    text: "信息来源———请选择是否可代填报",
                    value: "whether_registration",
                },
                {
                    text: "信息来源———请选择代报填写信息",
                    value: "registration_type",
                },
            ],

            // 编辑预览
            activeMainList: [],
            onlyPreviewStatus: false,

            // 修改时若需签名对象，
            signatureObj: {},
            // quoteNum:0

            // 图片模板
            imgStatus: false,
            imgTemplateList: [],

            // 允许修改结果
            modifyResults: "0",
        }
    },
    methods: {
        changeGroupStatus(val) {
            this.groupStatus = val
        },
        cancelGroup() {
            this.groupStatus = false
        },
        saveGroup() {
            let list = this.$refs.checkGroup.rightList
            console.log(list)
            if (list.length == 0) {
                this.$Message.warning({
                    content: "请选择群",
                    background: true,
                })
                return
            }
            let groupName = []
            let groupId = []
            list.map(item => {
                groupName.push(item.groupName)
                groupId.push(item.groupId)
            })
            this.formData.groupNames = groupName.join(",")
            this.formData.channels = groupId.join(",")
            this.groupStatus = false
        },
        changePushType(data) {
            if (data && data.length) {
                this.pushType = []
                this.pushType.push(data[data.length - 1])
                // this.formData.pushType = [...data[1]]
            }
        },
        toggleFn2() {
            this.toggle1 = false
        },
        // 表单编辑时预览
        openOnlyPreview() {
            this.activeMainList = JSON.parse(JSON.stringify(this.$refs.addChangeForm.mainList))
            this.onlyPreviewStatus = true
        },
        onlyPreviewStatusFn(status) {
            this.onlyPreviewStatus = status
        },

        changeRuleVos2(val) {
            this.ruleVos.splice(2, 1, {
                ruleCode: "button_name", //按钮名称
                ruleValue: val,
            })
        },
        changeruleVos3(val) {
            this.ruleVos.splice(3, 1, {
                ruleCode: "registration_type", //代报填写信息
                ruleValue: val,
            })
        },
        changeruleVos4(val) {
            this.ruleVos.splice(5, 1, {
                ruleCode: "signature", //填报用户签名确认
                ruleValue: val,
            })
        },
        changeruleVos5(val) {
            this.ruleVos.splice(6, 1, {
                ruleCode: "submit_only", //限制每人限填一次
                ruleValue: val,
            })
        },
        changeruleVos6(val) {
            this.ruleVos.splice(7, 1, {
                ruleCode: "modify_results", //限制每人限填一次
                ruleValue: val,
            })
        },
        loadUeditor() {
            if (this.formData.content != "" && this.formData.content != null && this.formData.content != undefined) {
                var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
                v_Obj.setContent(this.formData.content) //写入编辑器富文本内容
            } else {
                setTimeout(this.loadUeditor, 300)
            }
        },
        // 保存校验
        validateFn() {
            for (let k in this.validateArr) {
                if (
                    this.validateArr[k].value != "time" &&
                    this.validateArr[k].value != "terminal" &&
                    this.validateArr[k].value != "city" &&
                    this.validateArr[k].value != "cycleDates" &&
                    this.validateArr[k].value != "cycleTimes" &&
                    this.validateArr[k].value != "whether_registration" &&
                    this.validateArr[k].value != "registration_type" &&
                    (this.formData[this.validateArr[k].value] == "" || this.formData[this.validateArr[k].value] == undefined)
                ) {
                    this.$Message["error"]({
                        background: true,
                        content: this.validateArr[k].text,
                    })
                    return false
                }
                if (this.validateArr[k].value == "time" && typeof this.formData[this.validateArr[k].value][0] == "string") {
                    this.$Message["error"]({
                        background: true,
                        content: this.validateArr[k].text,
                    })
                    return false
                }
                if (this.validateArr[k].value == "terminal" && this.formData.terminal.length == 0) {
                    this.$Message["error"]({
                        background: true,
                        content: this.validateArr[k].text,
                    })
                    return false
                }
                if (this.validateArr[k].value == "city" && this[this.validateArr[k].value].length == 0) {
                    this.$Message["error"]({
                        background: true,
                        content: this.validateArr[k].text,
                    })
                    return false
                }
                if (this.validateArr[k].value == "cycleDates" && this.cycleDates.length == 0) {
                    this.$Message["error"]({
                        background: true,
                        content: this.validateArr[k].text,
                    })
                    return false
                }
                if (this.validateArr[k].value == "cycleTimes" && this.cycleTimes[0].timeValue[0] == "") {
                    this.$Message["error"]({
                        background: true,
                        content: this.validateArr[k].text,
                    })
                    return false
                }
                if (this.validateArr[k].value == "whether_registration" && this.ruleVos[3].ruleValue == "") {
                    this.$Message["error"]({
                        background: true,
                        content: this.validateArr[k].text,
                    })
                    return false
                }
                if (this.validateArr[k].value == "registration_type" && this.ruleVos[4].ruleValue == "" && this.ruleVos[3].ruleValue == "1") {
                    this.$Message["error"]({
                        background: true,
                        content: this.validateArr[k].text,
                    })
                    return false
                }
            }
            return true
        },
        contentFn() {
            //富文本预览
            var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
            this.formData.content = v_Obj.getContent() //获取富文本内容
            console.log(this.formData.content, "内容")

            if (!this.formData.content) {
                this.$Message.warning({
                    background: true,
                    content: "暂没内容，点击编辑吧！",
                    duration: 2,
                })
                return
            }

            this.$Message.loading({
                background: true,
                content: "正在加载，请稍等...",
            })

            let params = {
                content: this.formData.content,
            }
            this.$post(
                "/datamsg/api/pc/wxqr/createWxAppletQr",
                {
                    type: "RICH_TEXT_PREVIEW_PAGE",
                    value: JSON.stringify({
                        path: "pages/welcome/welcome",
                        redirectPath: "/indexPages/previewRichText/index",
                        params: {
                            ...params,
                        },
                    }),
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                console.log(res, "res")
                if (res.code == 200 && res.data) {
                    this.textObj.signInUrl = res.data.codeUrl
                    this.toggle1 = true
                    this.$Message.destroy()
                } else {
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        save() {
            // console.log(this.formData.time)
            // if (this.formData.pushChannel == true) {
            //     this.$Modal.confirm({
            //         title: "退出提示",
            //         content: "<p>当前发布包括推送，是否继续发布？</p>",
            //         onOk: () => {
            //             this.addPush()
            //         },
            //     })
            // } else {
            //     this.addPush()
            // }
            this.addPush()
        },
        addPush() {
            var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
            this.formData.content = v_Obj.getContent() //获取富文本内容
            this.formData.pushChannel = this.formData.pushChannel == false ? "" : "4"
            // 校验必填项
            if (!this.validateFn()) {
                return
            }
            this.$Message.loading({
                content: "正在保存数据，请稍等...",
                duration: 0,
            })
            this.formDetailVo = this.$refs.addChangeForm.exportFormData()

            if (this.formDetailVo.interactionFormVos && this.formDetailVo.interactionFormVos.length == 0) {
                this.$Message.destroy()
                this.$Message.error({
                    content: "填写设置--填写信息不能为空",
                    background: true,
                })
                return
            }
            console.log(this.formData.pushType)
            console.log(this.formData.channels)
            //  if(this.formData.pushType == 3 && !this.formData.channels){
            //       this.$Message.warning({
            //           content: "请填选择畅联组",
            //           background: true,
            //       })
            //       return
            //   }

            // zhw start

            if (this.formDetailVo && this.formDetailVo.interactionFormVos && Object.keys(this.formDetailVo).length != 0 && this.formDetailVo.interactionFormVos.length != 0) {
                this.formDetailVo.interactionFormVos.map(item => {
                    if (item.fieldParamVos.length != 0) {
                        let obj = {}
                        let isTrue = false
                        item.fieldParamVos.map(items => {
                            if (items.paramCode != "fieldType") {
                                switch (item.formType) {
                                    case "userName":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "姓名", value: "userName" }
                                        isTrue = true
                                        break
                                    case "mobile":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "手机号", value: "mobile" }
                                        isTrue = true
                                        break
                                    case "idNum":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "身份证号", value: "idNum" }
                                        isTrue = true
                                        break
                                    case "addr":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "地址", value: "addr" }
                                        isTrue = true
                                        break
                                    case "sex":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "性别", value: "sex" }
                                        isTrue = true
                                        break
                                    case "age":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "年龄", value: "age" }
                                        isTrue = true
                                        break
                                    case "marital":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "婚姻状况", value: "marital" }
                                        isTrue = true
                                        break
                                    case "workUnits":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "工作单位", value: "workUnits" }
                                        isTrue = true
                                        break
                                    case "national":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "民族", value: "national" }
                                        isTrue = true
                                        break
                                    case "brithday":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "出生日期", value: "brithday" }
                                        isTrue = true
                                        break
                                    case "political":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "政治面貌", value: "political" }
                                        isTrue = true
                                        break
                                    case "vaccines":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "新冠疫苗接种情况", value: "vaccines" }
                                        isTrue = true
                                        break
                                    case "brand":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "接种品牌", value: "brand" }
                                        isTrue = true
                                        break
                                    case "testing":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "核酸检测时间", value: "testing" }
                                        isTrue = true
                                        break
                                    case "result":
                                        obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "核酸检查结果", value: "result" }
                                        isTrue = true
                                        break
                                }
                            }
                        })

                        if (isTrue) {
                            item.fieldParamVos.push(obj)
                        }
                    }
                })
            }

            //  console.log('拦截看数据',this.formDetailVo);
            //   return

            if (this.ruleVos[0]) {
                if (this.ruleVos[0].ruleValue == "") {
                    this.ruleVos[0].ruleValue = ""
                }
                if (this.ruleVos[0].ruleValue == "1") {
                    this.ruleVos[0].ruleValue = "true"
                }
                if (this.ruleVos[0].ruleValue == "2") {
                    this.ruleVos[0].ruleValue = "false"
                }
                console.log("value", this.ruleVos[0].ruleValue)
            }

            // zhw end

            let url = ""
            let params = {}
            if (this.formData.pushType && this.formData.pushType.length != 0) this.formData.pushType = this.formData.pushType[0]
            else this.formData.pushType = ""
            if (this.addChangeIden) {
                if (this.formData.pushType && this.formData.pushType.length != 0) this.formData.pushType = this.formData.pushType[0]
                url = "/info/api/pc/information/dierc/create"
                if (this.ruleVos[5].ruleValue == "1") {
                    //代表需要签名，此时新增一条签名的题目
                    this.formDetailVo.interactionFormVos.push({
                        authentication: "",
                        content: "signature",
                        fieldCode: "signature",
                        fieldId: this.$core.randomString(),
                        fieldParamVos: [],
                        formType: "signature",
                        list: [],
                        relevance: "",
                        required: "1",
                        sequence: this.formDetailVo.interactionFormVos.length,
                        topic: "填报用户签名",
                        classField: "",
                        tbField: "",
                    })
                }
            } else {
                url = "/info/api/pc/information/dierc/update"
                params = {
                    id: this.activeId,
                }
                // if(this.formData.pushChannelVo){
                //   this.formData.pushChannelVo.channels = this.formData.channels
                //   this.formData.pushType = this.formData.pushType[0]
                //   console.log('推送修改')
                // }
                if (this.ruleVos[5].ruleValue == "1") {
                    //代表需要签名，此时新增一条签名的题目
                    if (JSON.stringify(this.signatureObj) === "{}") {
                        this.formDetailVo.interactionFormVos.push({
                            authentication: "",
                            content: "signature",
                            fieldCode: "signature",
                            fieldId: this.$core.randomString(),
                            fieldParamVos: [],
                            formType: "signature",
                            list: [],
                            relevance: "",
                            required: "1",
                            sequence: this.formDetailVo.interactionFormVos.length,
                            topic: "填报用户签名",
                            classField: "",
                            tbField: "",
                        })
                    } else {
                        this.formDetailVo.interactionFormVos.push(this.signatureObj)
                    }
                }
            }
            // let dataScopeIdList = (() => {
            //       if (this.project.length > 0) {
            //         return this.project;
            //       } else if (this.stree.length > 0) {
            //         return this.stree;
            //       } else if (this.region.length > 0) {
            //         return this.region;
            //       } else {
            //         return typeof this.city == "string"
            //           ? this.city.split(",")
            //           : this.city;
            //       }
            //  })()
            // console.log(this.project);
            // console.log(this.stree);
            // console.log(this.region);
            // console.log(this.city);
            // console.log('dataScopeIdList',dataScopeIdList);
            // return
            if (this.addChangeIden) {
                if (this.formDetailVo && this.formDetailVo.id) {
                    this.changeFromItemId()
                }
            }
            this.$post(
                url,
                {
                    ...this.formData,
                    ...params,
                    photo: this.formData.photo.indexOf("?") != "-1" ? this.formData.photo.substring(0, this.formData.photo.indexOf("?")) : this.formData.photo,
                    startTime: this.$core.formatDate(new Date(this.formData.time[0]), "yyyy-MM-dd hh:mm:ss"),
                    endTime: this.$core.formatDate(new Date(this.formData.time[1]), "yyyy-MM-dd hh:mm:ss"),
                    terminal: this.formData.terminal.indexOf("0") != -1 ? "0" : this.formData.terminal instanceof Array ? this.formData.terminal.join(",") : this.formData.terminal,
                    dataScopeIdList: (() => {
                        if (this.project.length > 0) {
                            return (this.project = typeof this.project == "string" ? [this.project] : this.project)
                        } else if (this.stree.length > 0) {
                            return (this.stree = typeof this.stree == "string" ? [this.stree] : this.stree)
                        } else if (this.region.length > 0) {
                            return (this.region = typeof this.region == "string" ? [this.region] : this.region)
                        } else {
                            return typeof this.city == "string" ? this.city.split(",") : this.city
                        }
                    })(),
                    formDetailVo: this.formDetailVo,
                    status: "1",
                    // recommend: "0",
                    sort: "100",
                    time: null,
                    columnCode: this.menuCodeObj.columnCode,
                    ruleVos: [
                        {
                            ruleCode: "authentication",
                            ruleValue: this.ruleVos[0].ruleValue,
                        },
                        {
                            ruleCode: "participate_integral",
                            ruleValue: this.ruleVos[1].ruleValue,
                        },
                        {
                            ruleCode: "button_name",
                            ruleValue: this.ruleVos[2].ruleValue == "其他" ? this.formData.buttonName : this.ruleVos[2].ruleValue,
                        },
                        {
                            ruleCode: "whether_registration",
                            ruleValue: this.ruleVos[3].ruleValue,
                        },
                        {
                            ruleCode: "registration_type",
                            ruleValue: this.ruleVos[4].ruleValue,
                        },
                        {
                            ruleCode: "signature", //填报用户签名确认
                            ruleValue: this.ruleVos[5].ruleValue,
                        },
                        {
                            ruleCode: "submit_only", //限制每人限填一次
                            ruleValue: this.ruleVos[6].ruleValue,
                        },
                        {
                            ruleCode: "modify_results", //限制每人限填一次
                            ruleValue: this.ruleVos[7].ruleValue,
                        },
                        {
                            ruleCode: "join_number_limit", //限制每人限填一次
                            ruleValue: this.ruleVos[8].ruleValue,
                        },
                    ],
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    operator: {
                        businessType: this.menuCodeObj.businessType,
                        functionType: this.menuCodeObj.functionType,
                        oemCode: parent.vue.oemInfo.oemCode,
                        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        terminal: this.formData.terminal.indexOf("0") != -1 ? "0" : this.formData.terminal instanceof Array ? this.formData.terminal.join(",") : this.formData.terminal,
                    },
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    this.$Message.destroy()
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "保存成功",
                        })
                        this.$emit("saveSuccess")
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "保存失败，请重试",
                    })
                })
        },
        // 模板库
        formTemplateStatusFn(status) {
            this.formTemplateStatus = status
        },
        changePage(size) {
            this.handleTableData({
                page: size,
                pageSize: this.pageSize,
            })
        },
        // 引用
        recommend(id) {
            this.$Message.loading({
                content: "正在加载模板数据，请稍等...",
                duration: 0,
            })
            this.infoId = ""
            this.$get("/datamsg/api/pc/dynamicform/copyFormDetail", {
                formId: id,
            })
                .then(res => {
                    if (res.code == 200 && res.data) {
                        this.formDetailVo = res.data
                        this.changeFormShow = ""
                        this.$nextTick(() => {
                            this.changeFormShow = true
                        })
                        this.$get("/datamsg/api/pc/dynamicform/citations", {
                            formId: id,
                        })
                        this.$Message.destroy()
                        this.formTemplateStatus = false
                    } else {
                        this.$Message.destroy()
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        // 预览
        previewStatusFn(status) {
            this.previewStatus = status
        },
        previewForm(id) {
            this.$Message.loading("正在加载数据,请稍等...")
            this.$get("/datamsg/api/pc/dynamicform/selectFormDetail", {
                formId: id,
            }).then(res => {
                this.$Message.destroy()
                if (res.code == 200 && res.data) {
                    this.previewFormData = res.data
                    this.previeswTitle = res.data.formTitle
                    this.previewStatus = true
                } else {
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        // tab切换
        toggleTab(number) {
            this.tabName = number
            this.handleTableData({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        handleTableData(obj) {
            this.$get("/datamsg/api/pc/dynamicform/selectFormPage", {
                orgCode: this.tabName == 0 ? "" : parent.vue.loginInfo.userinfo.orgCode,
                page: obj.page,
                pageSize: obj.pageSize,
                custGlobalId: this.tabName == 0 ? "" : parent.vue.loginInfo.userinfo.custGlobalId,
                templateType: this.tabName == 0 ? "2" : "1", //1：私有；2：公开
            })
                .then(res => {
                    this.$Message.destroy()
                    if (res.code == 200 && res.dataList) {
                        res.dataList.map(item => {
                            item.gmtCreate = this.$core.formatDate(new Date(item.gmtCreate), "yyyy-MM-dd hh:mm:ss")
                        })
                        this.formTemplateDate = res.dataList
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                        if (!this.formTemplateStatus) this.formTemplateStatus = true //代表打开弹窗
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        openFormTemp(obj) {
            this.$Message.loading({
                content: "正在加载模板数据，请稍等...",
                duration: 0,
            })
            this.handleTableData({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        // 切换菜单
        selectMenu(name) {
            this.showMenu = name
        },
        // 发布终端全选
        allSelect(arr) {
            if (this.terminalList.length == 6 && arr.indexOf("0") == -1) {
                this.formData.terminal = []
            } else if (this.terminalList.length == 6 && arr.indexOf("0") > -1) {
                arr.splice(arr.indexOf("0"), 1)
                this.formData.terminal = arr
            } else if (arr.length == 5 && arr.indexOf("0") == -1) {
                this.formData.terminal = ["0", "1", "2", "3", "4", "5"]
            } else if (arr.indexOf("0") > -1 && arr.length < 6) {
                this.formData.terminal = ["0", "1", "2", "3", "4", "5"]
            }
            this.terminalList = JSON.parse(JSON.stringify(this.formData.terminal))
        },
        // 添加开展时段
        addCycleTimes() {
            this.cycleTimes.push({
                limits: [
                    {
                        attrType: "person",
                        attrValue: "",
                    },
                ],
                timeValue: [],
            })
        },
        //取消裁剪
        cropperCancel(status) {
            this.cropperStatus = status
        },

        //图片裁剪
        async cropperFn() {
            if (!this.uploadData.host) {
                await this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                    if (res.code == 200) {
                        let now = this.$core.randomNumber()
                        //防止文件名有.
                        let suffixArr = this.formData.photo.split(".")
                        let suffix = "." + suffixArr[suffixArr.length - 1]
                        this.action = window.vue.getProxy()["/oss"].target
                        this.uploadData = {
                            name: now + suffix,
                            host: res.data.host,
                            key: res.data.key + now + suffix,
                            policy: res.data.encodedPolicy,
                            OSSAccessKeyId: res.data.accessId,
                            success_action_status: "200",
                            signature: res.data.postSignature,
                        }
                        this.imageName = now + suffix
                        this.imagePath = this.uploadData.host + this.uploadData.key
                    }
                })
            }

            let _this = this
            this.setAvatarBase64(this.formData.photo, base64 => {
                _this.option.img = base64
            })
            this.cropperStatus = true
        },
        // 设置图片base64
        setAvatarBase64(src, callback) {
            let _this = this
            let image = new Image()
            // 处理缓存
            image.src = src + "?v=" + Math.random()
            // 支持跨域图片
            image.crossOrigin = "*"
            image.onload = function () {
                let base64 = _this.transBase64FromImage(image)
                callback && callback(base64)
            }
        },
        // 将网络图片转换成base64格式
        transBase64FromImage(image) {
            let canvas = document.createElement("canvas")
            canvas.width = image.width
            canvas.height = image.height
            let ctx = canvas.getContext("2d")
            ctx.drawImage(image, 0, 0, image.width, image.height)
            // 可选其他值 image/jpeg
            return canvas.toDataURL("image/png")
        },
        //确定裁剪
        cropperFinish() {
            this.$refs.cropper.getCropBlob(data => {
                this.$refs.imgUpload.clearFiles()
                let formData = new FormData()
                formData.append("name", this.uploadData.name)
                formData.append("host", this.uploadData.host)
                formData.append("key", this.uploadData.key)
                formData.append("policy", this.uploadData.policy)
                formData.append("OSSAccessKeyId", this.uploadData.OSSAccessKeyId)
                formData.append("success_action_status", this.uploadData.success_action_status)
                formData.append("signature", this.uploadData.signature)
                formData.append("file", data)
                this.$post("https://liefeng.oss-cn-shenzhen.aliyuncs.com", formData, {
                    "Content-Type": "multipart/form-data",
                }).then(res => {
                    this.$Message.success({
                        background: true,
                        content: "裁剪完成",
                    })
                    this.formData.photo = ""
                    this.$nextTick(() => {
                        this.formData.photo = this.imagePath + "?" + new Date().getTime()
                        this.cropperCancel(false)
                    })
                })
            })
        },

        // 上传之前
        beforeUpload(file) {
            return this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                if (res.code == 200) {
                    let now = this.$core.randomNumber()
                    //防止文件名有.
                    let suffixArr = file.name.split(".")
                    let suffix = "." + suffixArr[suffixArr.length - 1]
                    this.action = window.vue.getProxy()["/oss"].target
                    this.uploadData = {
                        name: now + suffix,
                        host: res.data.host,
                        key: res.data.key + now + suffix,
                        policy: res.data.encodedPolicy,
                        OSSAccessKeyId: res.data.accessId,
                        success_action_status: "200",
                        signature: res.data.postSignature,
                    }
                    this.imageName = now + suffix
                    this.imagePath = res.data.host + res.data.key + this.imageName
                }
            })
        },
        //图片上传成功
        imageSuccess(file) {
            if (file.status == "finished") {
                this.formData.photo = this.imagePath
                this.$Message["success"]({
                    background: true,
                    content: "图片上传成功！",
                })
            } else {
                this.$Message["error"]({
                    background: true,
                    content: "图片上传失败！",
                })
            }
        },
        // 图片格式错误
        imageError(file) {
            this.$Notice.warning({
                title: "上传文件格式错误！",
                desc: "文件 " + file.name + " 上传失败, 请上传jpg, jpeg, png或gif格式的图片！",
            })
        },
        // 图片上传进度
        imageProgress(event, file, fileList) {
            this.file = file
            event.target.onprogress = event => {
                let uploadPercent = parseFloat(((event.loaded / event.total) * 100).toFixed(2)) // 保留两位小数
                // 手动设置显示上传进度条 以及上传百分比
                this.file.showProgress = true
                this.file.percentage = uploadPercent
            }
        },
        // 图片移除
        imageRemove(file) {
            this.formData.photo = ""
            this.file = {}
        },
        // 城市选择
        changeCity(code) {
            if (code.length >= 2) {
                this.regionList = []
                this.streeList = []
                this.projectList = []
                this.region = []
                this.stree = []
                this.project = []
                this.regionDisabled = true
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.regionDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region")
            }
        },
        // 区选择
        changeRegion(code) {
            if (code.length >= 2) {
                this.streeList = []
                this.projectList = []
                this.stree = []
                this.project = []
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.streeDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree")
            }
        },
        // 街道选择
        changeStree(code) {
            if (code.length >= 2) {
                this.projectList = []
                this.project = []
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.projectDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "projectList", "project")
            }
        },
        // 获取发布范围列表
        getPulishData(code, list, model) {
            this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                            value: item.orgPath,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                        }
                    })
                    if (res.dataList.length == "1") {
                        this[model] = [res.dataList[0].orgPath]
                    }
                }
            })
        },

        // 处理引用
        quoteData() {
            console.log("进来了")
            let data = this.formData
            // if(this.$route.query.templateId && this.quoteNum == 0){
            //   console.log(this.quoteNum)
            //   if (data.dataScopeList && data.dataScopeList.length == 1) {
            //       //回显发布范围
            //       data.dataScopeList.map((item) => {
            //         let arr = item.split("-");
            //         switch (arr.length) {
            //           case 2:
            //             this.city = item;
            //             break;
            //           case 3:
            //             this.city = arr[0] + "-" + arr[1];
            //             this.region = item;
            //             break;
            //           case 4:
            //             this.city = arr[0] + "-" + arr[1];
            //             this.region = arr[0] + "-" + arr[1] + "-" + arr[2];
            //             this.stree = item;
            //             break;
            //           case 5:
            //             this.city = arr[0] + "-" + arr[1];
            //             this.region = arr[0] + "-" + arr[1] + "-" + arr[2];
            //             this.stree =
            //               arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3];
            //             this.project = item;
            //             break;
            //         }
            //       });
            //     } else if (
            //       data.dataScopeList &&
            //       data.dataScopeList.length > 1
            //     ) {
            //       data.dataScopeList.map((item) => {
            //         let arr = item.split("-");
            //         switch (arr.length) {
            //           case 2:
            //             this.city.push(item);
            //             break;
            //           case 3:
            //             this.city.push(arr[0] + "-" + arr[1]);
            //             this.region.push(item);
            //             break;
            //           case 4:
            //             this.city.push(arr[0] + "-" + arr[1]);
            //             this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2]);
            //             this.stree.push(item);
            //             break;
            //           case 5:
            //             this.city.push(arr[0] + "-" + arr[1]);
            //             this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2]);
            //             this.stree.push(
            //               arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]
            //             );
            //             this.project.push(item);
            //             break;
            //         }
            //       });
            //       this.city = Array.from(new Set(this.city));
            //       this.region = Array.from(new Set(this.region));
            //       this.stree = Array.from(new Set(this.stree));
            //       this.project = Array.from(new Set(this.project));
            //     }
            // }

            this.formData = {
                title: data.title,
                recommend: data.recommend,
                pushChannel: data.pushChannel === "4" ? true : false, //小程序订阅通知
                time: [new Date(), new Date().setTime(new Date().getTime() + 3600 * 1000 * 24 * 365 * 5)],
                contactMobile: data.contactMobile,
                terminal: ["1", "3", "4"],
                photo: data.photo,
                content: data.content,
                source: parent.vue.loginInfo.userinfo.orgName,
            }
            if (data.formDetailVo) {
                this.formDetailVo = data.formDetailVo
                this.changeFormShow = true // 切换为表单修改组件
            }
            if (this.formDetailVo && this.formDetailVo.id) {
                this.changeFromItemId()
            }
            if (data.ruleVos) {
                data.ruleVos.map(item => {
                    console.log(item, "item")
                    if (item.ruleCode == "authentication") {
                        this.ruleVos[0] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue == "true" ? true : "",
                            // ruleValue:  item.ruleValue && item.ruleValue != '' ? (item.ruleValue == 'true' ? '1' : item.ruleValue == 'false' ? '2' : '') : '',
                        }
                    } else if (item.ruleCode == "participate_integral") {
                        this.ruleVos[1] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue ? Number(item.ruleValue) : "",
                        }
                    } else if (item.ruleCode == "button_name") {
                        if (item.ruleValue == "立即报名" || item.ruleValue == "立即填报" || item.ruleValue == "立即参与" || item.ruleValue == "立即预约") {
                            this.ruleVos[2] = {
                                ruleCode: item.ruleCode,
                                ruleValue: item.ruleValue,
                            }
                        } else {
                            this.ruleVos[2] = {
                                ruleCode: item.ruleCode,
                                ruleValue: "其他",
                            }
                            this.formData.buttonName = item.ruleValue
                        }
                    } else if (item.ruleCode == "whether_registration") {
                        this.ruleVos[3] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue,
                        }
                    } else if (item.ruleCode == "registration_type") {
                        this.ruleVos[4] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue,
                        }
                    } else if (item.ruleCode == "signature") {
                        this.ruleVos[5] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue,
                        }
                        if (item.ruleValue == "1" && this.formDetailVo.interactionFormVos) {
                            //需要签名
                            let index = this.formDetailVo.interactionFormVos.findIndex(res => res.content == "signature")
                            let signatureObj = this.formDetailVo.interactionFormVos.splice(index, 1)
                            if (signatureObj && signatureObj.length > 0) {
                                this.signatureObj = signatureObj[0]
                            }
                        }
                    } else if (item.ruleCode == "submit_only") {
                        this.ruleVos[6] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue,
                        }
                    } else if (item.ruleCode == "modify_results") {
                        this.ruleVos[7] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue,
                        }
                    }
                })
            }
        },
        // 图片模板
        selectTemplate() {
            if (this.imgTemplateList.length == 0) {
                this.$get("/datamsg/api/pc/fileUpload/selectFileUploadPage", {
                    groupId: 10000,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    fileType: "image",
                    page: 1,
                    pageSize: 100,
                }).then(res => {
                    if (res.code == 200) {
                        this.imgTemplateList = res.dataList
                    }
                })
            }
            this.imgStatus = true
        },
        changeImgStatus(val) {
            this.imgStatus = val
        },
        selectImg(path) {
            this.formData.photo = path
            this.imgStatus = false
        },
        changeFromItemId() {
            let formObj = JSON.parse(JSON.stringify(this.formDetailVo))
            // 保存之前的id
            let data = []
            // 保存更改之后的id
            let changeData = []
            formObj.interactionFormVos.map(item => {
                data.push(item.fieldId)
                item.fieldId = this.$core.randomString()
                changeData.push(item.fieldId)
            })

            formObj.id = this.$core.randomString()
            formObj.interactionFormVos.forEach(e => {
                e.fieldParamVos.forEach(item => {
                    item.id = this.$core.randomString()
                    item.fieldId = this.$core.randomString()
                })
                if (e.list && e.list.length != 0) {
                    e.list.forEach(oa => {
                        oa.field = this.$core.randomString()
                        let linkageFieldId = ""
                        if (oa.linkageFieldId && oa.linkageFieldId != "") {
                            data.map((item, index) => {
                                if (oa.linkageFieldId.search(item) != -1) {
                                    linkageFieldId = linkageFieldId + changeData[index] + ","
                                }
                            })
                        }
                        oa.linkageFieldId = linkageFieldId.charAt(linkageFieldId.length - 1) == "," ? linkageFieldId.substring(0, linkageFieldId.length - 1) : linkageFieldId
                    })
                }
            })

            this.formDetailVo = formObj
            console.log("数据提交之前，重新生成所有的id", this.formDetailVo)
        },
    },
    async created() {
        this.dimensionId = window.sessionStorage.getItem("dimensionId")
        sessionStorage.setItem("NODEENV", process.env.NODE_ENV)
        await this.getPulishData("44", "cityList", "city")
        if (this.activeId) {
            await this.$get("/info/api/pc/information/v2/queryById", {
                infoId: this.activeId,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                terminal: "2",
            })
                .then(res => {
                    if (res.code == 200 && res.data) {
                        this.$Message.destroy()
                        if (this.addType != "copy") {
                            if (res.data.dataScopeList && res.data.dataScopeList.length == 1) {
                                //回显发布范围
                                res.data.dataScopeList.map(item => {
                                    let arr = item.split("-")
                                    switch (arr.length) {
                                        case 2:
                                            this.city = item
                                            break
                                        case 3:
                                            this.city = arr[0] + "-" + arr[1]
                                            this.region = item
                                            break
                                        case 4:
                                            this.city = arr[0] + "-" + arr[1]
                                            this.region = arr[0] + "-" + arr[1] + "-" + arr[2]
                                            this.stree = item
                                            break
                                        case 5:
                                            this.city = arr[0] + "-" + arr[1]
                                            this.region = arr[0] + "-" + arr[1] + "-" + arr[2]
                                            this.stree = arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]
                                            this.project = item
                                            break
                                    }
                                })
                            } else if (res.data.dataScopeList && res.data.dataScopeList.length > 1) {
                                res.data.dataScopeList.map(item => {
                                    let arr = item.split("-")
                                    switch (arr.length) {
                                        case 2:
                                            this.city.push(item)
                                            break
                                        case 3:
                                            this.city.push(arr[0] + "-" + arr[1])
                                            this.region.push(item)
                                            break
                                        case 4:
                                            this.city.push(arr[0] + "-" + arr[1])
                                            this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                                            this.stree.push(item)
                                            break
                                        case 5:
                                            this.city.push(arr[0] + "-" + arr[1])
                                            this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                                            this.stree.push(arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3])
                                            this.project.push(item)
                                            break
                                    }
                                })
                                this.city = Array.from(new Set(this.city))
                                this.region = Array.from(new Set(this.region))
                                this.stree = Array.from(new Set(this.stree))
                                this.project = Array.from(new Set(this.project))
                            }
                        }

                        this.formData = {
                            title: res.data.title,
                            recommend: res.data.recommend,
                            pushChannel: res.data.pushChannel === "4" ? true : false, //小程序订阅通知
                            time:
                                this.addType != "copy"
                                    ? [new Date(res.data.startTime), new Date(res.data.endTime)]
                                    : [new Date(), new Date().setTime(new Date().getTime() + 3600 * 1000 * 24 * 365 * 5)],
                            contactMobile: res.data.contactMobile,
                            terminal: this.addType != "copy" ? (res.data.terminal.split(",").indexOf("0") != -1 ? ["0", "1", "2", "3", "4", "5"] : res.data.terminal.split(",")) : ["1", "3", "4"],
                            photo: res.data.photo,
                            content: res.data.content,
                            source: parent.vue.loginInfo.userinfo.orgName,
                        }
                        if (res.data.formDetailVo) {
                            this.formDetailVo = res.data.formDetailVo
                            this.changeFormShow = true // 切换为表单修改组件
                        }
                        if (this.addType == "copy") {
                            if (this.formDetailVo && this.formDetailVo.id) {
                                this.changeFromItemId()
                            }
                        } else {
                            if (res.data.pushChannelVo && res.data.pushChannelVo.pushType) {
                                this.formData.pushChannelVo = res.data.pushChannelVo
                                this.pushType = res.data.pushChannelVo.pushType && res.data.pushChannelVo.pushType != "" ? res.data.pushChannelVo.pushType.split(",") : []
                            }
                            if (res.data.groupList && res.data.groupList.length != 0) {
                                let arr = []
                                res.data.groupList.map(item => {
                                    arr.push(item.groupName)
                                })
                                this.formData.groupNames = arr.join(",")
                                this.formData.channels = res.data.pushChannelVo.channels
                                this.$refs.checkGroup.rightList = res.data.groupList
                            }
                        }

                        if (res.data.ruleVos) {
                            res.data.ruleVos.map(item => {
                                console.log(item, "item")
                                if (item.ruleCode == "authentication") {
                                    this.ruleVos[0] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue == "true" ? true : "",
                                        // ruleValue:  item.ruleValue && item.ruleValue != '' ? (item.ruleValue == 'true' ? '1' : item.ruleValue == 'false' ? '2' : '') : '',
                                    }
                                } else if (item.ruleCode == "participate_integral") {
                                    this.ruleVos[1] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue ? Number(item.ruleValue) : "",
                                    }
                                } else if (item.ruleCode == "button_name") {
                                    if (item.ruleValue == "立即报名" || item.ruleValue == "立即填报" || item.ruleValue == "立即参与" || item.ruleValue == "立即预约") {
                                        this.ruleVos[2] = {
                                            ruleCode: item.ruleCode,
                                            ruleValue: item.ruleValue,
                                        }
                                    } else {
                                        this.ruleVos[2] = {
                                            ruleCode: item.ruleCode,
                                            ruleValue: "其他",
                                        }
                                        this.formData.buttonName = item.ruleValue
                                    }
                                } else if (item.ruleCode == "whether_registration") {
                                    this.ruleVos[3] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                } else if (item.ruleCode == "registration_type") {
                                    this.ruleVos[4] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                } else if (item.ruleCode == "signature") {
                                    this.ruleVos[5] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                    if (item.ruleValue == "1" && this.formDetailVo.interactionFormVos) {
                                        //需要签名
                                        let index = this.formDetailVo.interactionFormVos.findIndex(res => res.content == "signature")
                                        let signatureObj = this.formDetailVo.interactionFormVos.splice(index, 1)
                                        if (signatureObj && signatureObj.length > 0) {
                                            this.signatureObj = signatureObj[0]
                                        }
                                    }
                                } else if (item.ruleCode == "submit_only") {
                                    this.ruleVos[6] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                } else if (item.ruleCode == "modify_results") {
                                    this.ruleVos[7] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                } else if (item.ruleCode == "join_number_limit") {
                                    this.ruleVos[8] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                }
                            })
                        }
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.destroy()
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        }
    },
    watch: {
        pushType: {
            handler(val) {
                this.formData.pushType = val
                console.log("watchType", this.formData.pushType)
            },
            immediate: true,
        },
        // addType:{
        //   handler(val,oval){
        //     if(val == 'add'){
        //       this.formData = {
        //         terminal: ["1", "3", "4"],
        //         time: (() => {
        //           const end = new Date();
        //           const start = new Date();
        //           end.setTime(start.getTime() + 3600 * 1000 * 24 * 365);
        //           return [start, end];
        //         })(),
        //         source: parent.vue.loginInfo.userinfo.orgName,
        //         recommend: '0',  //是否推荐,
        //         pushChannel: false, //小程序推送通知
        //       }
        //   }
        //   },
        //   immediate: true
        // }
    },
    components: {
        LiefengEditor,
        AddForm,
        LiefengModal,
        ChangeForm,
        PreviewForm,
        VueCropper,
        Mymodel,
        CheckGroup,
    },
}
</script>

<style scoped lang='less'>
// 截图
.cropper-content {
    .cropper {
        width: 580px;
        height: 428px;
    }
}
.container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 123px);
    /deep/.menu {
        height: 100%;
        .ivu-menu {
            height: 100%;
            width: 220px !important;
        }
    }
    .content {
        overflow: auto;
        flex: 1;
        height: 100%;
    }
}
/deep/.ivu-tooltip-inner {
    max-width: 100%;
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.dateTime {
    width: 372px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .ivu-checkbox-wrapper {
        width: 50px;
        height: 40px;
        line-height: 40px;
        margin: 6px;
        text-align: center;
        &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
            background: #72a2d4;
            color: #fff;
        }
        /deep/.ivu-checkbox {
            display: none;
        }
    }
}
.dateWeek {
    width: 550px;
    display: inline-flex;
    flex-wrap: nowrap;
    text-align: center;
    justify-content: flex-start;
    .ivu-checkbox-wrapper {
        flex: 1;
        &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
            background: #72a2d4;
            color: #fff;
        }
        /deep/.ivu-checkbox {
            display: none;
        }
    }
}
.tempContainer {
    /deep/.ivu-tabs-content {
        height: calc(100vh - 122px);
        .tempBox {
            height: calc(100% - 35px);
            overflow: auto;
            min-height: 200px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            & > div {
                width: 32%;
                height: 33%;
                min-height: 211px;
                margin: 0.5%;
                .ivu-card-head {
                    padding: 10px;
                    text-align: center;
                }
                .ivu-card-body {
                    padding: 10px;
                    line-height: 30px;
                    min-height: 142px;
                    height: 100%;
                    overflow: auto;
                }
            }
            .noList {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
.imgbox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: wrap;
    .imgitem {
        margin: 5px;
        width: 270px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
            box-shadow: 0 0 8px rgb(233, 61, 61);
        }
        img {
            width: 100%;
        }
    }
}
</style>
