<template>
  <div style="display: flex; justify-content: space-between; margin: 0 14px">
    <div style="width: 200px" class="left">
      <Input
        v-model.trim="treeVal"
        placeholder="请输入关键字进行过滤"
        style="width: 200px"
        @on-change="searchTree"
      />
      <Tree
        :data="treeData"
        @on-select-change="selectTree"
        style="width: 200px"
        :select-node="true"
      ></Tree>
    </div>
    <div class="main">
      <div class="searchForm">
        <Input v-model="inputVal" placeholder="请输入标题"></Input>
        <Button
          type="primary"
          style="margin: 0 10px"
          icon="ios-search"
          @click="search"
          >查询</Button
        >
        <Button type="success" icon="ios-refresh" @click="reset">重置</Button>
      </div>
      <div class="table">
        <LiefengTable
          :talbeColumns="talbeColumns1"
          :tableData="tableData1"
          :loading="loading1"
          :curPage="page1"
          :total="total1"
          :pagesizeOpts="[10, 20, 30, 50]"
          :page-size="pageSize1"
          :height="tableHeight"
          @hadlePageSize="hadlePageSize1"
          @tableSelect="tableSelect1"
        ></LiefengTable>
        <div class="btn">
          <div>
            <Button
              type="primary"
              @click="toRightTable"
              :disabled="selectTable1.length == 0"
              >>></Button
            >
            <Button
              icon="ios-trash"
              type="primary"
              @click="delRightTable"
              :disabled="selectTable2.length == 0"
            ></Button>
          </div>
        </div>
        <LiefengTable
          :talbeColumns="talbeColumns2"
          :tableData="tableData2"
          :height="tableHeight"
          :hidePage="true"
          @tableSelect="tableSelect2"
        ></LiefengTable>
      </div>
    </div>
  </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
export default {
props: ['infoId','infoCode',"menuCodeObj"],
  data() {
    return {
      treeVal: "",
      treeData: [],
      fullTreeData: [],
      //   菜单树定时器
      timeOut: null,
      //   表格1
      talbeColumns1: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "标题",
          key: "title",
          minWidth: 120,
          align: "center",
        },
        {
          title: "编码",
          width: 140,
          key: "code",
          align: "center",
        },
      ],
      tableData1: [],
      loading1: false,
      pageSize1: 10,
      page1: 1,
      total1: 0,
      //   表格1列表获取参数
      postData: {},
      inputVal: "",

      //   表格2
      talbeColumns2: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "标题",
          key: "title",
          minWidth: 120,
          align: "center",
        },
        {
          title: "状态",
          width: 140,
          key: "status",
          align: "center",
          render: (h,params) => {
              return h('Button',{
                  props: {
                      type: 'success',
                      ghost: true
                  },
                  style: {
                      cursor: 'default'
                  }
              },"生效中")
          }
        },
        {
          title: "编码",
          width: 140,
          key: "code",
          align: "center",
        },
      ],
      tableData2: [],

      //   table1,table2选中
      selectTable1: [],
      selectTable2: [],
      tableHeight: '',
    };
  },
  methods: {
      save() {
          let params = {
              links: (() => {
                 let arr = this.tableData2.map(item => {
                      return {
                          "businessType": item.businessType,
                            "functionType": item.functionType,
                            "infoCode": item.infoCode,
                            "infoId": item.infoId,
                            "status": "1",
                            "oemCode": item.oemCode,
                            "orgCode": item.orgCode
                      }
                  });
                  return arr
              })(),
              parentInfoId: this.infoId,
              parentInfoCode: this.infoCode,
              parentInfoOrgCode: parent.vue.loginInfo.userinfo.orgCode,
              parentOemCode: parent.vue.oemInfo.oemCode,
              columnCode: this.menuCodeObj.columnCode
          };
          this.$post('/datamsg/api/pc/information/addInformationLink',params,{"Content-Type": "application/json"}).then(res => {
              if(res.code == 200) {
                  this.$Message.success({
                      background: true,
                      content: "保存成功"
                  })
                  this.$emit('saveSuccess');
              }else {
                  this.$Message.error({
                      background: true,
                      content: res.desc
                  })
              }
          }).catch(err => {
              console.log(err);
              this.$Message.error({
                  background: true,
                  content: '提交数据失败！请联系管理员解决'
              })
          })
      },
    tableSelect1(arr) {
      this.selectTable1 = arr;
    },
    tableSelect2(arr) {
      this.selectTable2 = arr;
    },
    hadlePageSize1(obj) {
      this.loading1 = true;
      this.$get("/info/api/pc/information/v2/queryDataPage", {
        ...this.postData,
        page: obj.page,
        pageSize: obj.pageSize,
        title: this.inputVal,
        orgCode: parent.vue.loginInfo.userinfo.orgCode
      })
        .then((res) => {
          this.loading1 = false;
          if (res.code == 200 && res.dataList) {
            this.tableData1 = res.dataList.map(item =>{
              return{
                ...item,
                infoCode:item.code,
                infoId:item.id,
                oemCode:parent.vue.oemInfo.oemCode
              }
            });
            this.page1 = res.currentPage;
            this.pageSize1 = res.pageSize;
            this.total1 = res.maxCount;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败。请重试",
          });
        });
    },
    reset() {
      this.inputVal = "";
      this.search();
    },
    search() {
      this.hadlePageSize1({
        page: 1,
        pageSize: this.pageSize1,
      });
    },
    unique(arr) {
        let obj = {};
        arr = arr.reduce((pre,cur) => {
            obj[cur.infoId] ? '' : obj[cur.infoId] = pre.push(cur);
            return pre;
        },[]);
        return arr
    },
    toRightTable() {
        let arrId = [];
        this.selectTable1.map(item => {
            arrId.push(item.infoId);
        })
        let arrTable1 = [];
        this.tableData1.map((item,index,arr) => {
            if(arrId.indexOf(item.infoId) == -1) {
                arrTable1.push(item);
            }
        })
        this.tableData1 = arrTable1;
      this.tableData2 = this.unique(this.tableData2.concat(this.selectTable1));
      this.selectTable1 = [];
    },
    delRightTable() {
      let arrId = [];
        this.selectTable2.map(item => {
            arrId.push(item.infoId);
        })
        let arrTable2 = [];
        this.tableData2.map((item,index,arr) => {
            if(arrId.indexOf(item.infoId) == -1) {
                arrTable2.push(item);
            }
        })
        this.tableData2 = arrTable2;
      this.selectTable2 = [];
    },
    // 防抖搜索tree
    searchTree() {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.treeData = [];
        this.getSearchTree(this.fullTreeData);
      }, 500);
    },
    getSearchTree(list) {
      list.map((item, index) => {
        if (item.title.indexOf(this.treeVal) != -1) {
          //先找第一层
          this.treeData.push(item);
        } else if (item.children) {
          //目前子数据没有与夫数据关联起来的标识，无法做到搜索子数据的时候，把父数据也返回
          this.getSearchTree(item.children);
        }
      });
    },
    // 选择tree
    selectTree(obj) {
      if (obj && obj[0] && !!obj[0].columnCode) {
        this.postData = {
          orgCode: obj[0].orgCode,
          oemCode: obj[0].oemCode,
          columnCode: obj[0].columnCode,
          status: obj[0].businessType == 3 ? "2" : "4",
          columnType: obj[0].columnType,
          functionType: obj[0].functionType,
          businessType: obj[0].businessType,
          operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        };
        this.inputVal = '';
        this.hadlePageSize1({
          page: this.page,
          pageSize: this.pageSize1,
        });
      }
    },
    //   递归修改treeData
    getTreeData(arr) {
      let treeData = arr.map((item, index) => {
        let obj = {};
        if (item.subMenus && item.subMenus.length > 0) {
          obj = {
            expand: false,
            children: this.getTreeData(item.subMenus),
          };
          if (index == 0) {
            obj.expand = true;
          }
        }
        return {
          ...obj,
          ...item,
          title: item.name,
        };
      });
      return treeData;
    },
  },
  created() {
    this.tableHeight = window.innerHeight - 200 + '';
    var orgCodeCode = ''
    let detailList = JSON.parse(sessionStorage.getItem('userDetail'))
    if(!detailList.orgCode || detailList.orgCode == ''){
      if(detailList.orgUserVOS && detailList.orgUserVOS[0] && detailList.orgUserVOS[0].orgCode){
        orgCodeCode = detailList.orgUserVOS[0].orgCode
      }
    }else{
      orgCodeCode = detailList.orgCode
    }
    this.$get("/datamsg/api/pc/menu/findInformationBusinessTree.ac", {
      staffId: parent.vue.loginInfo.userinfo.id,
      operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      orgCode: orgCodeCode,
      oemCode: parent.vue.oemInfo.oemCode,
    })
      .then((res) => {
        if (res.code == 200 && res.dataList) {
          this.treeData = this.getTreeData(res.dataList);
          this.fullTreeData = JSON.parse(JSON.stringify(this.treeData));
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.$Message.error({
          background: true,
          content: "数据加载失败，请重试",
        });
      });

    //   获取已存在的右边表格数据
    this.$get('/datamsg/api/pc/information/getInformationLinkByParentInfoId',{
        parentInfoId: this.infoId
    }).then(res => {
        if(res.code == 200 && res.dataList) {
            this.tableData2 = res.dataList;
        }else {
            this.$Message.error({
                background: true,
                content: res.desc
            })
        }
    })
  },
  components: {
    LiefengTable,
  },
};
</script>

<style scoped lang='less'>
.left {
  /deep/.ivu-input {
    border-radius: 4px 4px 0 0;
  }
}
.main {
  width: calc(100vw - 254px);
  height: calc(100vh - 155px);
  margin-left: 20px;
  .searchForm {
    width: calc(50% - 50px);
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .ivu-input {
      flex: 1;
    }
  }
  .table {
    display: flex;
    justify-content: space-between;
    & > div {
      width: calc(50% - 50px);
    }
    .btn {
      width: 100px;
      text-align: center;
      height: calc(100vh - 200px);
      position: relative;
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /deep/button {
          width: 50px;
          height: 32px;
          display: block;
          margin-bottom: 16px;
          &.ivu-btn-primary[disabled] {
            background-color: #57c5f7;
            border-color: #57c5f7;
            color: #fff;
          }
        }
      }
    }
  }
}
/deep/.ivu-tree {
  padding: 0 6px;
  border-left: 1px solid #dcdee2;
  border-right: 1px solid #dcdee2;
  border-bottom: 1px solid #dcdee2;
  height: calc(100vh - 155px);
  overflow: auto;
}
// #57c5f7
</style>
